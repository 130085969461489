import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    nationalities: [],
    regions: [],
    municipalities: [],
    parameter: null,
    parameters: [],
    parametersObject: {},
    streetTypes: [],
    applicationRoles: [],
    teamChangeProcesses: [],
    locations: [],
    neighborhoods: [],
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_NATIONALITIES(state, nationalities) {
    state.nationalities = nationalities
  },
  UPDATE_REGIONS(state, regions) {
    state.regions = regions
  },
  UPDATE_MUNICIPALITIES(state, municipalities) {
    state.municipalities = municipalities
  },
  UPDATE_NEIGHBORHOODS(state, neighborhoods) {
    state.neighborhoods = neighborhoods
  },
  UPDATE_LOCATIONS(state, locations) {
    state.locations = locations
  },
  UPDATE_PARAMETER(state, parameter) {
    state.parameter = parameter
  },
  UPDATE_PARAMETERS(state, parameters) {
    state.parameters = parameters
    // Creating Object from array of parameters objects
    const mapped = parameters.map((item) => ({
      [item.code]: item.value,
    }))
    state.parametersObject = Object.assign({}, ...mapped)
  },
  UPDATE_STREET_TYPES(state, streetTypes) {
    state.streetTypes = streetTypes
  },
  UPDATE_APPLICATION_ROLES(state, applicationRoles) {
    state.applicationRoles = applicationRoles
  },
  RESET_COMMON_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  RESET_TEAM_CHANGE_PROCESSES(state) {
    state.teamChangeProcesses = []
  },
  UPDATE_TEAM_CHANGE_PROCESSES(state, teamChangeProcesses) {
    state.teamChangeProcesses = teamChangeProcesses
  },
}

export const actions = {
  // GET NATIONALITIES
  // GET
  async fetchNationalitiesAction(store) {
    const url = `${BASE}/co/api/v2/countries`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_NATIONALITIES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  // REGIONS
  // GET
  async fetchRegions(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/regions${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_REGIONS', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  async fetchLocations(store, municipality) {
    store.commit('UPDATE_LOCATIONS', [])

    const url = `${BASE}/co/api/v2/locations/${municipality}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_LOCATIONS', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  async fetchNeighborhoods(store, location) {
    store.commit('UPDATE_NEIGHBORHOODS', [])
    const url = `${BASE}/co/api/v2/neighborhoods/${location}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_NEIGHBORHOODS', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  // MUNICIPALITIES
  // GET
  async fetchMunicipalities(store, region) {
    store.commit('UPDATE_MUNICIPALITIES', [])
    let queryString = ''
    if (region) {
      queryString = `?filter[region]=${region}`
    }

    const url = `${BASE}/co/api/v2/municipalities` + encodeURI(queryString)
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_MUNICIPALITIES', res.data.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  // STREET TYPES
  // GET
  async fetchStreetTypes(store) {
    const url = `${BASE}/co/api/v2/streetTypes`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_STREET_TYPES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // FETCH PARAMETER
  // GET
  async fetchParameterById(store, id) {
    store.commit('UPDATE_PARAMETER', null)
    try {
      const response = await axios.get(`${BASE}/api/v2/parameters/${id}`)
      store.commit('UPDATE_PARAMETER', response.data)
    } catch (error) {
      throw new Error(`API Error occurred at api/v2/parameters/${id}`)
    }
  },
  // FETCH PARAMETER BY CODE
  // GET
  async fetchParameterByCode(store, parameterCode) {
    const parameterFromLocalStorage = JSON.parse(
      localStorage.getItem(parameterCode),
    )
    if (!parameterFromLocalStorage) {
      store.commit('UPDATE_PARAMETER', null)
      try {
        const response = await axios.get(
          `${BASE}/api/v2/parameters/code/${parameterCode}`,
        )
        store.commit('UPDATE_PARAMETER', response.data)
        localStorage.setItem(parameterCode, JSON.stringify(response.data))

        return response
      } catch (error) {
        throw new Error(
          `API Error occurred at api/v2/parameters/code/${parameterCode}`,
        )
      }
    } else {
      store.commit('UPDATE_PARAMETER', parameterFromLocalStorage)
    }
  },

  // PARAMETERS
  // GET
  async fetchParameters(store) {
    store.commit('UPDATE_PARAMETERS', [])
    const url = `${BASE}/api/v2/parameters`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_PARAMETERS', response.data)
    } catch (error) {
      throw new Error('API Error occurred at api/v2/parameters.')
    }
  },

  // ROLES
  // GET
  async fetchApplicationRoles(store) {
    const url = `${BASE}/api/v2/actor/role/roles`
    try {
      const response = await axios.get(url)
      store.commit('UPDATE_APPLICATION_ROLES', response.data)
    } catch (error) {
      throw new Error('API Error occurred at /api/v2/actor/role/roles.')
    }
  },

  // NEW PARAMETER
  // POST
  async parameterNew(store, payload) {
    try {
      return await axios.post(`${BASE}/api/v2/parameters`, payload)
    } catch (e) {
      throw Error('API Error occurred at post api/v2/parameters.')
    }
  },
  // EDIT PARAMETER
  // PATCH
  async parameterEdit(store, payload) {
    try {
      return await axios.patch(
        `${BASE}/api/v2/parameters/${payload.id}`,
        payload.command,
      )
    } catch (e) {
      throw Error('API Error occurred at patch api/v2/parameters.')
    }
  },
  // FETCH TEAM CHANGE PROCESSES
  // GET
  async fetchTeamChangeProcesses(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/team/change${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TEAM_CHANGE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // CANCEL TEAM CHANGE PROCESS
  // POST
  async cancelTeamChangeProcess(store, payload) {
    try {
      return await axios.post(`${BASE}/co/api/v2/team/change/cancel`, payload)
    } catch (e) {
      throw Error('API Error occurred at post api/v2/team/change/cancel.')
    }
  },
}
