import axios from '@utils/axiosInstance'
import queryStringFilterBuilder from '@utils/queryStringFilterBuilder'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

export const state = {
  collaboratorEvents: [],
  collaboratorEventsOriginal: [],
  collaboratorsWithEvents: null,
  hourTypologies: JSON.parse(localStorage.getItem('hourTypologies'))
    ? JSON.parse(localStorage.getItem('hourTypologies'))
    : [],
  hourTypologiesPlanningTemplate: JSON.parse(
    localStorage.getItem('hourTypologiesPlanningTemplate'),
  )
    ? JSON.parse(localStorage.getItem('hourTypologiesPlanningTemplate'))
    : [],
  hourTypology: null,
  hourParent: null,
  planningRule: null,
  planningRules: [],
  groupingCexReport: null,
  groupingsCexReport: [],
  siteCex: [],
  hourParents: [],
  planningTemplate: [],
  planningTemplatePeriods: [],
  teamWeekPlanningEvents: [],
  collaboratorTeamWeekPlanning: [],
  collaboratorWeekPlanning: [],
  templateManagementWeeks: null,
  siteQuarterlyNotifications: null,
  teamQuarterlyNotifications: null,
  collaboratorQuarterlyNotification: [],
  signedQuarterlyNotification: [],
  collaboratorsWithPendingNotifications: [],
  weeklyNotifications: [],
  managedWeeklyNotifications: [],
  collaboratorWeeklyNotification: null,
  signWeeklyNotificationDocument: null,
  collaboratorsWithPendingWeeklyNotifications: [],
  legalErrors: [],
  quarterlyNotificationSignDate: null,
  globalPlanning: [],
  collaboratorPendingWeeklyNotifications: [],
  collaboratorPlanningDifferences: {
    startWorkingTime: null,
    weekNumber: null,
    differences: [],
    weekDates: [],
    collaborator: null,
    team: null,
  },
  collaboratorWeekPlanningModificationDifferences: [],
  cexSummary: [],
  vacationsSummary: [],
  vacationsAgreementProcessStatus: [],
  collaboratorVacationsDetails: null,
  pendingQuarterlyNotificationCollaborator: [],
  planningToDownload: null,
  calendarLoading: false,
  totalCexHoursTeamContractType: [],
  totalCexHoursTeamContractTypeByDirector: [],
  alertPendingWeeklyNotifications: null,
  legalPlanningErrorsInQuarter: null,
  timebaseReport: null,
  alertPendingWeeklyNotificationsByDirector: null,
  legalPlanningErrorsInQuarterByDirector: null,
  defaultTimeSlots: [],
  siteTimeSlotsIntervals: [],
  siteTimeSlotsInterval: null,
  activeSiteTimeSlots: [],
  reportShiftWorkAnalysis: [],
  collaboratorData: null,
  myGameHoursData: [],
}

export const getters = {}

export const mutations = {
  UPDATE_HOUR_TYPOLOGY(state, hourTypology) {
    state.hourTypology = hourTypology
  },
  UPDATE_HOUR_TYPOLOGIES(state, hourTypologies) {
    localStorage.setItem('hourTypologies', JSON.stringify(hourTypologies))
    state.hourTypologies = hourTypologies
  },
  UPDATE_HOUR_TYPOLOGIES_PLANNING_TEMPLATE(
    state,
    hourTypologiesPlanningTemplate,
  ) {
    localStorage.setItem(
      'hourTypologiesPlanningTemplate',
      JSON.stringify(hourTypologiesPlanningTemplate),
    )
    state.hourTypologiesPlanningTemplate = hourTypologiesPlanningTemplate
  },
  UPDATE_PLANNING_RULE(state, planningRule) {
    state.planningRule = planningRule
  },
  UPDATE_PLANNING_RULES(state, planningRules) {
    state.planningRules = planningRules
  },
  UPDATE_PLANNING_GROUPING_CEX(state, groupingCexReport) {
    state.groupingCexReport = groupingCexReport
  },
  UPDATE_PLANNING_GROUPINGS_LIST_CEX(state, groupingsCexReport) {
    state.groupingsCexReport = groupingsCexReport
  },
  UPDATE_SITE_CEX(state, siteCex) {
    state.siteCex = siteCex
  },
  UPDATE_HOUR_PARENT(state, hourParent) {
    state.hourParent = hourParent
  },
  UPDATE_HOUR_PARENTS(state, hourParents) {
    state.hourParents = hourParents
  },
  UPDATE_PLANNING_TEMPLATE(state, planningTemplate) {
    state.planningTemplate = planningTemplate
  },
  UPDATE_PLANNING_TEMPLATE_PERIODS(state, planningTemplate) {
    state.planningTemplatePeriods = formatTemplatePeriods(planningTemplate)
  },
  UPDATE_COLLABORATOR_TEAM_WEEK_PLANNING(state, collaboratorTeamWeekPlanning) {
    state.collaboratorTeamWeekPlanning = collaboratorTeamWeekPlanning
  },
  UPDATE_COLLABORATOR_WEEK_PLANNING(state, collaboratorWeekPlanning) {
    state.collaboratorWeekPlanning = collaboratorWeekPlanning
  },
  TOGGLE_CALENDAR_LOADING(state, status) {
    state.calendarLoading = status
  },
  UPDATE_TEAM_WEEK_PLANNING_EVENTS(state, teamWeekPlanning) {
    state.teamWeekPlanningEvents =
      formatTeamWeekPlanningEvents(teamWeekPlanning)
  },
  UPDATE_COLLABORATOR_MONTH_PLANNING_MANAGER(state, collaboratorEvents) {
    state.collaboratorEvents =
      formatCollaboratorMonthPlanningEvents(collaboratorEvents)
    state.collaboratorEventsOriginal =
      formatCollaboratorMonthPlanningEvents(collaboratorEvents)
  },
  UPDATE_COLLABORATOR_BASIC_DATA(state, collaboratorData) {
    state.collaboratorData = collaboratorData
  },
  UPDATE_COLLABORATOR_MONTH_PLANNING(state, collaboratorEvents) {
    state.collaboratorEvents =
      formatCollaboratorMonthPlanningEvents(collaboratorEvents)
  },
  UPDATE_TEMPLATE_MANAGEMENT_WEEKS(state, templateManagementWeeks) {
    state.templateManagementWeeks = templateManagementWeeks
  },
  UPDATE_SITE_QUARTERLY_NOTIFICATIONS(state, siteQuarterlyNotifications) {
    state.siteQuarterlyNotifications = siteQuarterlyNotifications
  },
  UPDATE_TEAM_QUARTERLY_NOTIFICATIONS(state, teamQuarterlyNotifications) {
    state.teamQuarterlyNotifications = teamQuarterlyNotifications
  },
  UPDATE_COLLABORATOR_QUARTERLY_NOTIFICATIONS(
    state,
    collaboratorQuarterlyNotification,
  ) {
    state.collaboratorQuarterlyNotification =
      formatCollaboratorMonthPlanningEvents(collaboratorQuarterlyNotification)
  },
  UPDATE_SIGNED_QUARTERLY_NOTIFICATION(state, signedQuarterlyNotification) {
    state.signedQuarterlyNotification = formatCollaboratorMonthPlanningEvents(
      signedQuarterlyNotification,
    )
  },
  UPDATE_QUARTERLY_NOTIFICATIONS_SIGN_DATE(
    state,
    quarterlyNotificationSignDate,
  ) {
    state.quarterlyNotificationSignDate = quarterlyNotificationSignDate
  },
  UPDATE_COLLABORATORS_WITH_PENDING_NOTIFICATIONS(
    state,
    collaboratorsWithPendingNotifications,
  ) {
    state.collaboratorsWithPendingNotifications =
      collaboratorsWithPendingNotifications
  },
  UPDATE_WEEKLY_NOTIFICATIONS(state, weeklyNotifications) {
    state.weeklyNotifications = weeklyNotifications
  },
  UPDATE_MANAGED_WEEKLY_NOTIFICATIONS(state, managedWeeklyNotifications) {
    state.managedWeeklyNotifications = managedWeeklyNotifications
  },
  UPDATE_COLLABORATOR_WEEKLY_NOTIFICATIONS(
    state,
    collaboratorWeeklyNotification,
  ) {
    state.collaboratorWeeklyNotification = collaboratorWeeklyNotification
  },
  UPDATE_LEGAL_ERRORS(state, legalErrors) {
    state.legalErrors = legalErrors
  },
  UPDATE_GLOBAL_PLANNING(state, globalPlanning) {
    state.globalPlanning = globalPlanning
  },

  UPDATE_COLLABORATOR_PLANNING_DIFFERENCES(
    state,
    collaboratorPlanningDifferences,
  ) {
    state.collaboratorPlanningDifferences = collaboratorPlanningDifferences
  },
  UPDATE_COLLABORATOR_PENDING_WEEKLY_NOTIFICATIONS(
    state,
    collaboratorPendingWeeklyNotifications,
  ) {
    state.collaboratorPendingWeeklyNotifications =
      collaboratorPendingWeeklyNotifications
  },
  UPDATE_COLLABORATOR_WEEK_PLANNING_MODIFICATION_DIFFERENCES(
    state,
    collaboratorWeekPlanningModificationDifferences,
  ) {
    state.collaboratorWeekPlanningModificationDifferences =
      formatCollaboratorWeekPlanningModificationDifferences(
        collaboratorWeekPlanningModificationDifferences,
      )
  },
  UPDATE_PLANNING_TO_DOWNLOAD(state, planningToDownload) {
    if (planningToDownload != null) {
      state.planningToDownload = {
        ...planningToDownload,
        collaborators: planningToDownload.collaborators.map((collaborator) => ({
          ...collaborator,
          periods: formatCollaboratorMonthPlanningEvents(collaborator.periods),
        })),
      }
    } else {
      state.planningToDownload = null
    }
  },
  UPDATE_CEX_SUMMARY(state, cexSummary) {
    state.cexSummary = cexSummary
  },
  // ///////////////
  // TEMPLATE EVENTS
  // ///////////////
  ADD_EVENT_TEMPLATE(state, event) {
    state.planningTemplatePeriods.push(event)
  },
  REMOVE_EVENT_TEMPLATE(state, event) {
    const eventIndex = state.planningTemplatePeriods.findIndex(
      (e) => e.id === event.id,
    )
    state.planningTemplatePeriods.splice(eventIndex, 1)
  },
  UPDATE_EVENT_TEMPLATE(state, payload) {
    const eventToUpdate = getTemplateEventToUpdate(state, payload.event.event)
    eventToUpdate.title = `${Math.abs(
      (eventToUpdate.end - eventToUpdate.start) / 36e5,
    )}h ${payload.hourTypologyData.abbreviation}`
    eventToUpdate.hourTypologyUuid = payload.hourTypologyData.uuid
    eventToUpdate.hourTypology = payload.hourTypologyData.abbreviation
    eventToUpdate.color = payload.hourTypologyData.color
  },
  RESIZE_EVENT_TEMPLATE(state, event) {
    const eventToUpdate = getTemplateEventToUpdate(state, event.event)
    eventToUpdate.title = `${Math.abs(
      (event.event.end - event.event.start) / 36e5,
    )}h ${eventToUpdate.hourTypology}`
    eventToUpdate.start = event.event.startStr
    eventToUpdate.end = event.event.endStr
  },
  DROP_EVENT_TEMPLATE(state, event) {
    const eventToUpdate = getTemplateEventToUpdate(state, event.event)
    eventToUpdate.title = `${Math.abs(
      (event.event.end - event.event.start) / 36e5,
    )}h ${eventToUpdate.hourTypology}`
    eventToUpdate.resourceId = event.newResource
      ? event.newResource.id
      : eventToUpdate.resourceId
    eventToUpdate.start = event.event.startStr
    eventToUpdate.end = event.event.endStr
  },
  // ////////////////
  // TEAM WEEK EVENTS
  // ////////////////
  ADD_EVENT(state, event) {
    state.teamWeekPlanningEvents.push(event)
  },
  UPDATE_EVENT(state, payload) {
    const index = state.teamWeekPlanningEvents.findIndex(
      (_event) => _event.id.toString() === payload.event.id.toString(),
    )
    const eventToUpdate = state.teamWeekPlanningEvents[index]

    eventToUpdate.title = `${Math.abs(
      (eventToUpdate.end - eventToUpdate.start) / 36e5,
    )}h ${payload.hourTypologyData.abbreviation}`
    eventToUpdate.hourTypologyUuid = payload.hourTypologyData.uuid
    eventToUpdate.hourTypology = payload.hourTypologyData.abbreviation
    eventToUpdate.color = payload.hourTypologyData.color
  },
  DROP_EVENT(state, arg) {
    const index = state.teamWeekPlanningEvents.findIndex(
      (_event) => _event.id.toString() === arg.event.id.toString(),
    )
    state.teamWeekPlanningEvents[index].title = `${Math.abs(
      (arg.event.end - arg.event.start) / 36e5,
    )}h ${state.teamWeekPlanningEvents[index].hourTypology}`
    state.teamWeekPlanningEvents[index].resourceId = arg.newResource
      ? arg.newResource.id
      : state.teamWeekPlanningEvents[index].resourceId
    state.teamWeekPlanningEvents[index].start = arg.event.startStr
    state.teamWeekPlanningEvents[index].end = arg.event.endStr
  },
  RESIZE_EVENT(state, arg) {
    const index = state.teamWeekPlanningEvents.findIndex(
      (_event) => _event.id.toString() === arg.event.id.toString(),
    )
    state.teamWeekPlanningEvents[index].title = `${Math.abs(
      (arg.event.end - arg.event.start) / 36e5,
    )}h ${state.teamWeekPlanningEvents[index].hourTypology}`
    state.teamWeekPlanningEvents[index].start = arg.event.startStr
    state.teamWeekPlanningEvents[index].end = arg.event.endStr
  },
  REMOVE_EVENT(state, event) {
    const index = state.teamWeekPlanningEvents.findIndex(
      (_event) => _event.id.toString() === event.id.toString(),
    )
    state.teamWeekPlanningEvents.splice(index, 1)
  },
  EDIT_EVENT(state, hourTypologyData) {
    const index = state.teamWeekPlanningEvents.findIndex(
      (_event) => _event.id === hourTypologyData.event.event.id,
    )
    state.teamWeekPlanningEvents[index].hourTypology =
      hourTypologyData.hourTypology
    state.teamWeekPlanningEvents[index].color = hourTypologyData.eventColor
    state.teamWeekPlanningEvents[index].title = `${Math.abs(
      (hourTypologyData.event.event.end - hourTypologyData.event.event.start) /
        36e5,
    )}h ${hourTypologyData.hourTypology}`
  },
  // /////////////////////////
  // COLLABORATOR MONTH EVENTS
  // /////////////////////////
  ADD_EVENT_COLLABORATOR(state, event) {
    state.collaboratorEvents.push(event)
  },
  REMOVE_EVENT_COLLABORATOR(state, event) {
    const index = state.collaboratorEvents.findIndex((_event) => {
      return _event.id === event.id
    })
    state.collaboratorEvents.splice(index, 1)
  },
  UPDATE_EVENT_COLLABORATOR(state, payload) {
    const index = state.collaboratorEvents.findIndex((_event) => {
      return _event.id === payload.event.id
    })
    const eventToUpdate = state.collaboratorEvents[index]

    eventToUpdate.title = `${Math.abs(
      (eventToUpdate.end - eventToUpdate.start) / 36e5,
    )}h ${payload.hourTypologyData.abbreviation}`
    eventToUpdate.hourTypologyUuid = payload.hourTypologyData.uuid
    eventToUpdate.hourTypology = payload.hourTypologyData.abbreviation
    eventToUpdate.color = payload.hourTypologyData.color
  },
  DROP_EVENT_COLLABORATOR(state, arg) {
    const index = state.collaboratorEvents.findIndex(
      (_event) => _event.id === arg.event.id,
    )
    state.collaboratorEvents[index].title = `${Math.abs(
      (arg.event.end - arg.event.start) / 36e5,
    )}h ${state.collaboratorEvents[index].hourTypology}`
    state.collaboratorEvents[index].start = arg.event.startStr
    state.collaboratorEvents[index].end = arg.event.endStr
  },
  RESIZE_EVENT_COLLABORATOR(state, arg) {
    const index = state.collaboratorEvents.findIndex((_event) => {
      return _event.id === arg.event.id
    })
    state.collaboratorEvents[index].title = `${Math.abs(
      (arg.event.end - arg.event.start) / 36e5,
    )}h ${state.collaboratorEvents[index].hourTypology}`
    state.collaboratorEvents[index].start = arg.event.startStr
    state.collaboratorEvents[index].end = arg.event.endStr
  },
  ORDER_COLLABORATOR_EVENTS(state) {
    state.collaboratorEvents.sort(
      (a, b) => new Date(a.start) - new Date(b.start),
    )
  },
  // VACATIONS SUMMARY
  UPDATE_VACATIONS_SUMMARY(state, vacationsSummary) {
    state.vacationsSummary = vacationsSummary
  },
  // RESET VACATIONS AGREEMENT PROCESS STATUS
  RESET_UPDATE_VACATION_AGREEMENT_PROCESS_STATUS() {
    state.vacationsAgreementProcessStatus = []
  },
  // VACATIONS AGREEMENT PROCESS STATUS
  UPDATE_VACATION_AGREEMENT_PROCESS_STATUS(
    state,
    vacationsAgreementProcessStatus,
  ) {
    state.vacationsAgreementProcessStatus.push(vacationsAgreementProcessStatus)
  },
  // COLLABORATOR VACATIONS DETAILS
  UPDATE_COLLABORATOR_VACATIONS_DETAILS(state, collaboratorVacationsDetails) {
    state.collaboratorVacationsDetails = collaboratorVacationsDetails
  },
  // RESET NOTIFICATIONS FOR COLLABORATOR ROLE PENDINGS QUARTERS
  RESET_PENDING_QUARTERLY_NOTIFICATION_FOR_COLLABORATOR() {
    state.pendingQuarterlyNotificationCollaborator = []
  },
  // QUARTERLY NOTIFICATIONS FOR COLLABORATOR ROLE
  UPDATE_PENDING_QUARTERLY_NOTIFICATION_FOR_COLLABORATOR(
    state,
    pendingQuarterlyNotificationCollaborator,
  ) {
    // state.pendingQuarterlyNotificationCollaborator = pendingQuarterlyNotificationCollaborator
    state.pendingQuarterlyNotificationCollaborator.push(
      pendingQuarterlyNotificationCollaborator,
    )
  },
  UPDATE_QUARTERLY_NOTIFICATIONS_BY_COLLABORATOR(
    state,
    viewQuarterlyNotificationCollaborator,
  ) {
    state.collaboratorQuarterlyNotification =
      formatCollaboratorMonthPlanningEvents(
        viewQuarterlyNotificationCollaborator.proposals,
      )
  },
  UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE(
    state,
    totalCexHoursTeamContractType,
  ) {
    state.totalCexHoursTeamContractType.push(totalCexHoursTeamContractType)
  },
  UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE_BY_DIRECTOR(
    state,
    totalCexHoursTeamContractTypeByDirector,
  ) {
    state.totalCexHoursTeamContractTypeByDirector =
      totalCexHoursTeamContractTypeByDirector
  },
  RESET_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE(
    state,
    totalCexHoursTeamContractType,
  ) {
    state.totalCexHoursTeamContractType = totalCexHoursTeamContractType
  },
  UPDATE_ALERT_PENDING_WEEKLY_NOTIFICATIONS(
    state,
    alertPendingWeeklyNotifications,
  ) {
    state.alertPendingWeeklyNotifications = alertPendingWeeklyNotifications
  },
  UPDATE_LEGAL_PLANNING_ERRORS_IN_QUARTER(state, legalPlanningErrorsInQuarter) {
    state.legalPlanningErrorsInQuarter = legalPlanningErrorsInQuarter
  },
  UPDATE_TIMEBASE_REPORT(state, timebaseReport) {
    state.timebaseReport = timebaseReport
  },
  ALERT_PENDING_WEEKLY_NOTIFICATIONS_BY_DIRECTOR(
    state,
    alertPendingWeeklyNotificationsByDirector,
  ) {
    state.alertPendingWeeklyNotificationsByDirector =
      alertPendingWeeklyNotificationsByDirector
  },
  LEGAL_PLANNING_ERRORS_IN_QUARTER_BY_DIRECTOR(
    state,
    legalPlanningErrorsInQuarterByDirector,
  ) {
    state.legalPlanningErrorsInQuarterByDirector =
      legalPlanningErrorsInQuarterByDirector
  },
  UPDATE_DEFAULT_TIME_SLOTS(state, defaultTimeSlots) {
    state.defaultTimeSlots = defaultTimeSlots
  },
  UPDATE_SITE_TIME_SLOTS_INTERVALS(state, siteTimeSlotsIntervals) {
    state.siteTimeSlotsIntervals = siteTimeSlotsIntervals
  },
  UPDATE_SITE_TIME_SLOTS_INTERVAL(state, siteTimeSlotsInterval) {
    state.siteTimeSlotsInterval = siteTimeSlotsInterval
  },
  UPDATE_ACTIVE_SITE_TIME_SLOTS(state, activeSiteTimeSlots) {
    state.activeSiteTimeSlots = activeSiteTimeSlots
  },
  UPDATE_REPORT_SHIFT_WORK_ANALYSIS(state, reportShiftWorkAnalysis) {
    state.reportShiftWorkAnalysis = reportShiftWorkAnalysis
  },
  UPDATE_SITE_TIME_SLOT_INTERVAL_ANALYSIS(
    state,
    reportSiteTimeSlotIntervalAnalysis,
  ) {
    state.reportSiteTimeSlotIntervalAnalysis =
      reportSiteTimeSlotIntervalAnalysis
  },
  UPDATE_MY_GAME_HOURS_DATA(state, myGameHoursData) {
    state.myGameHoursData = myGameHoursData
  },
}

export const actions = {
  // HOUR TYPOLOGY
  // GET
  async fetchHourTypologyAction(store, uuid) {
    try {
      store.commit('UPDATE_HOUR_TYPOLOGY', null)
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/hourTypologies/${uuid}`,
      )
      store.commit('UPDATE_HOUR_TYPOLOGY', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/planning/api/v2/hourTypologies.',
      )
    }
  },
  // HOUR PARENT
  // GET
  async fetchHourParentAction(store, uuid) {
    try {
      store.commit('UPDATE_HOUR_PARENT', null)
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/hourParents/${uuid}`,
      )
      store.commit('UPDATE_HOUR_PARENT', res.data)
    } catch (error) {
      throw new Error('API Error occurred in /co/planning/api/v2/hourParents.')
    }
  },
  // HOUR TYPOLOGIES
  // GET
  async fetchHourTypologiesAction(store) {
    const hourTypologiesFromLS = JSON.parse(
      localStorage.getItem('hourTypologies'),
    )
    if (!hourTypologiesFromLS || hourTypologiesFromLS.length === 0) {
      try {
        store.commit('UPDATE_HOUR_TYPOLOGIES', [])
        const res = await axios.get(`${BASE}/co/planning/api/v2/hourTypologies`)
        store.commit('UPDATE_HOUR_TYPOLOGIES', res.data)
      } catch (error) {
        throw new Error(
          'API Error occurred in /co/planning/api/v2/hourTypologies.',
        )
      }
    }
  },
  // HOUR TYPOLOGIES
  // GET
  async fetchHourTypologiesByCollaboratorAction(store) {
    const hourTypologiesFromLS = JSON.parse(
      localStorage.getItem('hourTypologies'),
    )
    if (!hourTypologiesFromLS) {
      try {
        store.commit('UPDATE_HOUR_TYPOLOGIES', [])
        const res = await axios.get(
          `${BASE}/co/planning/api/v2/PlanningManager/hourTypologyByCollaborator`,
        )
        store.commit('UPDATE_HOUR_TYPOLOGIES', res.data)
      } catch (error) {
        throw new Error(
          'API Error occurred in /co/planning/api/v2/PlanningManager/hourTypologyByCollaborator.',
        )
      }
    }
  },
  // TEMPLATE HOUR TYPOLOGIES
  // GET
  async fetchTemplateHourTypologiesAction(store) {
    const hourTypologiesPlanningTemplateFromLS = JSON.parse(
      localStorage.getItem('hourTypologiesPlanningTemplate'),
    )
    if (!hourTypologiesPlanningTemplateFromLS) {
      try {
        store.commit('UPDATE_HOUR_TYPOLOGIES_PLANNING_TEMPLATE', [])
        const res = await axios.get(
          `${BASE}/co/planning/api/v2/hourTypologies/planningTemplate`,
        )
        store.commit('UPDATE_HOUR_TYPOLOGIES_PLANNING_TEMPLATE', res.data)
      } catch (error) {
        throw new Error(
          'API Error occurred in /co/planning/api/v2/hourTypologies/planningTemplate.',
        )
      }
    }
  },

  // PLANNING RULE
  // GET
  async fetchPlanningRuleAction(store, uuid) {
    try {
      store.commit('UPDATE_PLANNING_RULE', null)
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planningRules/${uuid}`,
      )
      store.commit('UPDATE_PLANNING_RULE', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/planningRules/${uuid}.`,
      )
    }
  },
  // PLANNING RULES
  // GET
  async fetchPlanningRulesAction(store) {
    try {
      store.commit('UPDATE_PLANNING_RULES', [])
      const res = await axios.get(`${BASE}/co/planning/api/v2/planningRules`)
      store.commit('UPDATE_PLANNING_RULES', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/planningRules.${error}`,
      )
    }
  },
  // GROUPING CEX SPECIFIC
  // GET
  async fetchGroupingCexReportAction(store, uuid) {
    try {
      store.commit('UPDATE_PLANNING_GROUPING_CEX', null)
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/groupingCexReport/${uuid}`,
      )
      store.commit('UPDATE_PLANNING_GROUPING_CEX', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /planning/api/v2/groupingCexReport/${uuid}.`,
      )
    }
  },
  // GROUPINGS CEX LIST
  // GET
  async fetchGroupingsListCexReportAction(store) {
    try {
      store.commit('UPDATE_PLANNING_GROUPINGS_LIST_CEX', [])
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/groupingCexReport`,
      )
      store.commit('UPDATE_PLANNING_GROUPINGS_LIST_CEX', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /planning/api/v2/groupingCexReport.`,
      )
    }
  },

  // SITE CEX
  // GET
  async fetchSiteCexAction(store, sitePersonnelDivision) {
    try {
      store.commit('UPDATE_SITE_CEX', [])
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/siteCEX/${sitePersonnelDivision}`,
      )
      store.commit('UPDATE_SITE_CEX', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/siteCEX/${sitePersonnelDivision}.`,
      )
    }
  },
  // HOUR PARENTS
  // GET
  async fetchHourParentsAction(store) {
    try {
      store.commit('UPDATE_HOUR_PARENTS', [])
      const res = await axios.get(`${BASE}/co/planning/api/v2/hourParents`)
      store.commit('UPDATE_HOUR_PARENTS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in /co/planning/api/v2/hourParents.`)
    }
  },

  // PLANNING TEMPLATE
  // GET
  async fetchPlanningTemplateAction(store, teamUuid) {
    try {
      store.commit('UPDATE_PLANNING_TEMPLATE', [])
      store.commit('UPDATE_PLANNING_TEMPLATE_PERIODS', [])
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planningTemplates/${teamUuid}`,
      )
      store.commit('UPDATE_PLANNING_TEMPLATE', res.data)
      store.commit('UPDATE_PLANNING_TEMPLATE_PERIODS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planningTemplates/${teamUuid}`,
      )
    }
  },
  // TEAM WEEK PLANNING BY COLLABORATOR
  // GET
  async fetchTeamWeekPlanningByCollaboratorAction(store, filters) {
    store.commit('UPDATE_COLLABORATOR_TEAM_WEEK_PLANNING', [])
    const { date } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/collaboratorWeekTeam?filter[startDateInterval]=${date}`,
      )
      store.commit('UPDATE_COLLABORATOR_TEAM_WEEK_PLANNING', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planning/collaboratorWeekTeam?filter[startDateInterval]=${date}: ${error}`,
      )
    }
  },
  async toggleCalendarLoading(store, status) {
    store.commit('TOGGLE_CALENDAR_LOADING', status)
  },
  // TEAM WEEK PLANNING BY MANAGER
  // GET
  async fetchTeamWeekPlanningByManagerAction(store, filters) {
    store.commit('TOGGLE_CALENDAR_LOADING', true)
    store.commit('UPDATE_TEAM_WEEK_PLANNING_EVENTS', [])
    const { date, teamUuid } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/weekTeamByManager?filter[startDateInterval]=${date}&filter[teamUuid]=${teamUuid}`,
      )
      store.commit('UPDATE_TEAM_WEEK_PLANNING_EVENTS', res.data)
      store.commit('TOGGLE_CALENDAR_LOADING', false)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planning/weekTeamByManager?filter[startDateInterval]=${date}&filter[teamUuid]=${teamUuid}: ${error}`,
      )
    }
  },
  // WEEK PLANNING BY COLLABORATOR
  // GET
  async fetchWeekPlanningByCollaboratorAction(store) {
    store.commit('UPDATE_COLLABORATOR_WEEK_PLANNING', null)
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/collaboratorWeek`,
      )
      store.commit('UPDATE_COLLABORATOR_WEEK_PLANNING', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planning/collaboratorWeek: ${error}`,
      )
    }
  },
  // COLLABORATOR MONTH OWN
  // GET
  async fetchCollaboratorOwnMonthPlanningAction(store, filters) {
    store.commit('UPDATE_COLLABORATOR_MONTH_PLANNING', [])
    const { monthStartDate, collaboratorUuid } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/monthlyPlanningByCollaborator?filter[month]=${monthStartDate}&filter[collaboratorUuid]=${collaboratorUuid}`,
      )
      store.commit(
        'UPDATE_COLLABORATOR_MONTH_PLANNING',
        res.data.collaboratorPeriods,
      )
      store.commit('UPDATE_COLLABORATOR_BASIC_DATA', res.data.collaboratorData)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planning/monthlyPlanningByCollaborator?filter[month]=${monthStartDate}&filter[collaboratorUuid]=${collaboratorUuid}: ${error}`,
      )
    }
  },
  // COLLABORATOR MONTH PLANNING BY MANAGER
  // GET
  async fetchCollaboratorMonthPlanningByManagerAction(store, filters) {
    store.commit('UPDATE_COLLABORATOR_MONTH_PLANNING_MANAGER', [])
    store.commit('UPDATE_COLLABORATOR_BASIC_DATA', null)
    const { monthStartDate, collaboratorUuid, print } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/collaboratorMonthlyPlanningByManagerProfile?filter[month]=${monthStartDate}&filter[collaboratorUuid]=${collaboratorUuid}&filter[print]=${print}`,
      )
      store.commit(
        'UPDATE_COLLABORATOR_MONTH_PLANNING_MANAGER',
        res.data.collaboratorPeriods,
      )
      store.commit('UPDATE_COLLABORATOR_BASIC_DATA', res.data.collaboratorData)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planning/collaboratorMonthlyPlanningByManagerProfile?filter[date]=${monthStartDate}&filter[collaboratorUuid]=${collaboratorUuid}&filter[collaboratorUuid]=${collaboratorUuid}&filter[print]=${print}: ${error}`,
      )
    }
  },
  // WEEKS
  // GET
  async fetchWeeksForTemplateManagementAction(store, filters) {
    store.commit('UPDATE_TEMPLATE_MANAGEMENT_WEEKS', null)
    const { date, teamUuid } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planningModifications/weeks?filter[startDateInterval]=${date}&filter[teamUuid]=${teamUuid}`,
      )
      store.commit('UPDATE_TEMPLATE_MANAGEMENT_WEEKS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/planning/api/v2/planningModifications/weeks?filter[startDateInterval]=${date}&filter[teamUuid]=${teamUuid}`,
      )
    }
  },
  // SITE QUARTERLY NOTIFICATIONS
  // GET
  async fetchSiteQuarterlyNotificationsAction(store, filters) {
    store.commit('UPDATE_SITE_QUARTERLY_NOTIFICATIONS', null)
    const { personnelDivision, year, quarter } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/quarterlyNotification/site/${personnelDivision}/${year}/${quarter}`,
      )
      store.commit('UPDATE_SITE_QUARTERLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/quarterlyNotification/site/${personnelDivision}/${year}/${quarter}: ${error}`,
      )
    }
  },
  // TEAM QUARTERLY NOTIFICATIONS
  // GET
  async fetchTeamQuarterlyNotificationsAction(store, filters) {
    store.commit('UPDATE_TEAM_QUARTERLY_NOTIFICATIONS', null)
    const { teamUuid, year, quarter } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/quarterlyNotification/team/${teamUuid}/${year}/${quarter}`,
      )
      store.commit('UPDATE_TEAM_QUARTERLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/quarterlyNotification/team/${teamUuid}/${year}/${quarter}: ${error}`,
      )
    }
  },

  // COLLABORATORS WITH PENDING QUARTERLY NOTIFICATIONS
  // GET
  async fetchCollaboratorsWithPendingNotificationsAction(store, filters) {
    store.commit('UPDATE_COLLABORATORS_WITH_PENDING_NOTIFICATIONS', [])
    const { personnelDivision, year, quarter } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/quarterlyNotification/openStatus/actor/${personnelDivision}/${year}/${quarter}`,
      )
      store.commit('UPDATE_COLLABORATORS_WITH_PENDING_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/quarterlyNotification/openStatus/actor/${personnelDivision}/${year}/${quarter}: ${error}`,
      )
    }
  },

  // WEEKLY NOTIFICATIONS
  // GET
  async fetchWeeklyNotificationsAction(store, filters) {
    store.commit('UPDATE_WEEKLY_NOTIFICATIONS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/notifications/weeklyNotificationsInQuarter${queryString}`

    try {
      const res = await axios.get(url)
      store.commit('UPDATE_WEEKLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // OPEN WEEKLY NOTIFICATIONS
  // GET
  async fetchOpenWeeklyNotificationsAction(store, filters) {
    store.commit('UPDATE_WEEKLY_NOTIFICATIONS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/notifications/openWeeklyNotificationsInQuarter${queryString}`

    try {
      const res = await axios.get(url)
      store.commit('UPDATE_WEEKLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // PENDING WEEKLY NOTIFICATIONS
  // GET
  async fetchManagedWeeklyNotificationsAction(store, filters) {
    store.commit('UPDATE_MANAGED_WEEKLY_NOTIFICATIONS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/notifications/managedWeeklyNotificationsInQuarter${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_MANAGED_WEEKLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // WEEKLY NOTIFICATIONS COLLABORATOR
  // GET
  async fetchCollaboratorPendingWeeklyNotifications(store) {
    store.commit('UPDATE_COLLABORATOR_PENDING_WEEKLY_NOTIFICATIONS', [])
    const url = `${BASE}/co/planning/api/notifications/pendingWeeklyNotificationsByCollaborator`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_PENDING_WEEKLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // LEGAL ERRORS
  // GET
  async fetchLegalErrorsAction(store, params) {
    store.commit('UPDATE_LEGAL_ERRORS', [])
    const filtersArr = []
    Object.keys(params.filters).forEach((key) => {
      if (params.filters[key]) {
        filtersArr.push(`filter[${key}]=${params.filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}${params.path}${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_LEGAL_ERRORS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // GLOBAL PLANNING BY MANAGER
  // GET
  async fetchGlobalPlanningByManagerAction(store, filters) {
    store.commit('UPDATE_GLOBAL_PLANNING', [])
    const { personnelDivision, date, fictitious, hourTypologyToView } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/planningGlobalByManagerProfile?filter[personnelDivision]=${personnelDivision}&filter[date]=${date}&filter[fictitious]=${fictitious}&filter[hourTypologyToView]=${hourTypologyToView}`,
      )
      store.commit('UPDATE_GLOBAL_PLANNING', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/planning/planningGlobalByManagerProfile?filter[personnelDivision]=${personnelDivision}&filter[date]=${date}&filter[fictitious]=${fictitious}&filter[hourTypologyToView]=${hourTypologyToView}: ${error}`,
      )
    }
  },
  // GLOBAL PLANNING BY COLLABORATOR
  // GET
  async fetchGlobalPlanningByCollaboratorAction(store, filters) {
    store.commit('UPDATE_GLOBAL_PLANNING', [])
    const { personnelDivision, date } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/planningGlobalByCollaboratorProfile?filter[personnelDivision]=${personnelDivision}&filter[date]=${date}`,
      )
      store.commit('UPDATE_GLOBAL_PLANNING', res.data)
    } catch (error) {
      console.warn(
        `API Error occurred in /co/planning/api/v2/planning/planningGlobalByCollaboratorProfile?filter[personnelDivision]=${personnelDivision}&filter[date]=${date}: ${error}`,
      )
    }
  },
  // CEX SUMMARY
  // GET
  async fetchCexSummaryAction(store, filters) {
    store.commit('UPDATE_CEX_SUMMARY', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/v2/planning/totalCexWeekMonth${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CEX_SUMMARY', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // DOWNLOAD PLANNING
  // GET
  async fetchPlanningToDownloadAction(store, filters) {
    store.commit('UPDATE_PLANNING_TO_DOWNLOAD', null)
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/v2/PlanningManager/collaboratorPlanningMassiveDownload${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_PLANNING_TO_DOWNLOAD', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // /////////////
  // POST
  // /////////////

  // HOUR TYPOLOGY CREATE
  // POST
  async hourTypologyCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/hourTypologies/create.hourTypology`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/hourTypologies/create.hourTypology`,
      )
    }
  },
  // HOUR TYPOLOGY UPDATE
  // POST
  async hourTypologyUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/hourTypologies/edit.hourTypology`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/hourTypologies/edit.hourTypology`,
      )
    }
  },
  // HOUR PARENT CREATE
  // POST
  async hourParentCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/hourParents/create.hourParent`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/hourParents/create.hourParent`,
      )
    }
  },
  // HOUR PARENT UPDATE
  // POST
  async hourParentUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/hourParents/edit.hourParent`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/hourParents/edit.hourParent`,
      )
    }
  },
  // PLANNING RULE CREATE
  // POST
  async planningRuleCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/planningRules/create.planningRule`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningRules/create.planningRule`,
      )
    }
  },
  // PLANNING RULE UPDATE
  // POST
  async planningRuleUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/planningRules/edit.planningRule`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningRules/edit.planningRule`,
      )
    }
  },
  // PLANNING RULE DELETE
  // POST
  async planningRuleDeleteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/planningRules/delete.planningRule`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningRules/delete.planningRule`,
      )
    }
  },
  // GROUPING TYPE CREATE
  // POST
  async groupingCexCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/groupingCexReport/create.groupingCexReport`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /planning/api/v2/groupingCexReport/create.groupingCexReport`,
      )
    }
  },
  // GROUPING TYPE UPDATE
  // POST
  async groupingCexReportUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/groupingCexReport/edit.groupingCexReport`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /planning/api/v2/groupingCexReport/edit.groupingCexReport`,
      )
    }
  },
  // GROUPING TYPE DELETE
  // POST
  async groupingCexReportDeleteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/groupingCexReport/delete.groupingCexReport`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /planning/api/v2/groupingCexReport/delete.groupingCexReport`,
      )
    }
  },
  // SITE CEX CREATE
  // POST
  async siteCexCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/siteCEX/create.siteCEX`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/siteCEX/create.siteCEX`,
      )
    }
  },
  // SITE CEX UPDATE
  // POST
  async siteCexUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/siteCEX/edit.siteCEX`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/siteCEX/edit.siteCEX`,
      )
    }
  },
  // PLANNING TEMPLATE CREATE
  // POST
  async planningTemplateCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/planningTemplates/create.planningTemplate`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningTemplates/create.planningTemplate`,
      )
    }
  },
  // PLANNING TEMPLATE DELETE
  // POST
  async planningTemplateDeleteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/planningTemplates/delete.planningTemplate`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningTemplates/delete.planningTemplate`,
      )
    }
  },
  // PLANNING TEMPLATE COPY IN WEEKS
  // POST
  async planningTemplateCopyAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/PlanningManager/copyFromPlanningTemplate`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/PlannningManager/copyFromPlanningTemplate`,
      )
    }
  },
  // SEND QUARTERLY NOTIFICATION COLLABORATOR
  // POST
  async sendCollaboratorQuarterlyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/sendCollaborator`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /planning/api/v2/quarterlyNotification/sendCollaborator: ${error}`,
      )
    }
  },
  // SEND QUARTERLY NOTIFICATION SITE
  // POST
  async sendSiteQuarterlyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/sendSite`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/quarterlyNotification/sendSite: ${error}`,
      )
    }
  },
  // SEND QUARTERLY NOTIFICATION TEAM
  // POST
  async sendTeamQuarterlyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/sendTeam`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/quarterlyNotification/sendTeam: ${error}`,
      )
    }
  },
  // SIGN ON PAPER QUARTERLY NOTIFICATION
  // POST
  async signOnPaperQuarterlyNotificationAction({ commit }, payload) {
    try {
      const res = await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/signOnPaper`,
        payload,
      )
      commit('UPDATE_SIGNED_QUARTERLY_NOTIFICATION', res.data.aggregates.data)
      commit(
        'UPDATE_QUARTERLY_NOTIFICATIONS_SIGN_DATE',
        res.data.aggregates.signAt,
      )
      return res
    } catch (error) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/quarterlyNotification/signOnPaper: ${error}`,
      )
    }
  },
  // VIEW SIGN QUARTERLY NOTIFICATION DOCUMENT
  // POST
  async viewSignedQuarterlyNotificationAction({ commit }, payload) {
    try {
      commit('UPDATE_SIGNED_QUARTERLY_NOTIFICATION', [])
      commit('UPDATE_QUARTERLY_NOTIFICATIONS_SIGN_DATE', null)
      const res = await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/viewSignOnPaperQuarterlyNotification`,
        payload,
      )
      commit('UPDATE_SIGNED_QUARTERLY_NOTIFICATION', res.data.aggregates.data)
      commit(
        'UPDATE_QUARTERLY_NOTIFICATIONS_SIGN_DATE',
        res.data.aggregates.signAt,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/quarterlyNotification/viewSignOnPaperQuarterlyNotification: ${error}`,
      )
    }
  },
  // SEND WEEKLY NOTIFICATION SITE
  // POST
  async sendWeeklyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/notifications/sendWeeklyNotification`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /planning/api/v2/weeklyNotification/send: ${error}`,
      )
    }
  },
  // SEND MULTIPLE WEEKLY NOTIFICATION SITE
  // POST
  async sendMultipleWeeklyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/notifications/sendWeeklyNotification`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /planning/api/v2/weeklyNotification/send: ${error}`,
      )
    }
  },
  // ACCEPT WEEKLY NOTIFICATION
  // POST
  async acceptWeeklyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/notifications/acceptWeeklyNotification`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /planning/api/v2/weeklyNotification/accept: ${error}`,
      )
    }
  },
  // CLOSE WEEKLY NOTIFICATION
  // POST
  async closeWeeklyNotificationAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/notifications/closeWeeklyNotification`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /planning/api/v2/weeklyNotification/close: ${error}`,
      )
    }
  },
  // GET PLANNING DIFFERENCES BY NOTIFICATION UUID
  // POST
  async fetchPlanningDifferencesByNotification(store, payload) {
    const url = `${BASE}/co/planning/api/v2/planningModifications/differencesByNotification`
    try {
      store.commit(
        'UPDATE_COLLABORATOR_WEEK_PLANNING_MODIFICATION_DIFFERENCES',
        [],
      )
      const res = await axios.post(url, payload)
      store.commit('UPDATE_COLLABORATOR_PLANNING_DIFFERENCES', res.data.data)
      // store.commit(
      //   'UPDATE_COLLABORATOR_WEEK_PLANNING_MODIFICATION_DIFFERENCES',
      //   res.data.data.differences,
      // )
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // COPY PLANNING
  // POST
  async copyPlanningAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/PlanningManager/copyPlanningFromCollaboratorToAnother`,
        payload,
      )
    } catch (error) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/planningManager/copyPlanningFromCollaboratorToAnother: ${error}`,
      )
    }
  },
  // SAVE TEAM WEEK PLANNING
  // POST
  async saveTeamWeekPlanningAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/PlanningManager/teamWeekPlanning`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/PlanningManager/teamWeekPlanning: ${e}`,
      )
    }
  },
  // SAVE WEEK FOR COLLABORATOR MONTH PLANNING
  // POST
  async saveWeekCollaboratorMonthPlanningAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/PlanningManager/collaboratorMonthPlanning`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/PlanningManager/collaboratorMonthPlanning: ${e}`,
      )
    }
  },
  // VIEW COLLABORATOR QUARTERLY NOTIFICATION
  // POST
  async viewCollaboratorQuarterlyNotificationAction({ commit }, payload) {
    try {
      commit('UPDATE_COLLABORATOR_QUARTERLY_NOTIFICATIONS', [])
      const res = await axios.post(
        `${BASE}/co/planning/api/v2/quarterlyNotification/viewQuarterlyNotification`,
        payload,
      )
      commit(
        'UPDATE_COLLABORATOR_QUARTERLY_NOTIFICATIONS',
        res.data.aggregates.data,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/v2/quarterlyNotification/viewQuarterlyNotification: ${error}`,
      )
    }
  },
  // CHECK IDENTIFICATION DOCUMENT VALIDATION (DOBLE FACTOR VERIFICATION TO ACCEPT/REJECT A PURPOSED PLANNING)
  // GET
  async checkIdentificationDocumentVerificationAction(
    { commit },
    identificationNumber,
    identificationNumberType,
  ) {
    const filter = `?filter[identificationDocument]=${identificationNumber}&filter[identificationDocumentType]=${identificationNumberType}`
    const url = `${BASE}/co/api/v2/identificationDocumentVerification${filter}`
    try {
      const res = await axios.get(url)
      return res.data.result
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // ACCEPT COLLABORATOR COMMUNICATED WEEKLY NOTIFICATION ACTION
  async acceptCollaboratorWeeklyNotificationAction({ commit }, payload) {
    const url = `${BASE}/co/planning/api/notifications/acceptWeeklyNotificationByCollaborator`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // REJECT COLLABORATOR COMMUNICATED WEEKLY NOTIFICATION ACTION
  async rejectCollaboratorWeeklyNotificationAction({ commit }, payload) {
    const url = `${BASE}/co/planning/api/notifications/rejectWeeklyNotificationByCollaborator`
    try {
      const res = await axios.post(url, payload)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // VACATIONS SUMMARY
  async fetchVacationsSummaryAction({ commit }, payload) {
    const { personnelDivision, year, teamUuid } = payload
    const url = `${BASE}/co/planning/api/v2/vacation/summary?filter[personnelDivision]=${personnelDivision}&filter[year]=${year}&filter[teamUuid]=${teamUuid}`
    try {
      commit('UPDATE_VACATIONS_SUMMARY', [])
      const res = await axios.get(url)
      commit('UPDATE_VACATIONS_SUMMARY', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  async fetchCollaboratorVacationsDetailsAction({ commit }, payload) {
    const { year, collaboratorUuid } = payload
    const url = `${BASE}/co/planning/api/v2/vacation/details?filter[year]=${year}&filter[collaboratorUuid]=${collaboratorUuid}`
    try {
      commit('UPDATE_COLLABORATOR_VACATIONS_DETAILS', null)
      const resp = await axios.get(url, payload)
      commit('UPDATE_COLLABORATOR_VACATIONS_DETAILS', resp.data)
      return resp
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  async fetchVacationsAgreementProcessStatusActionReset({ commit }) {
    try {
      await commit('RESET_UPDATE_VACATION_AGREEMENT_PROCESS_STATUS')
    } catch (error) {
      throw new Error(
        `API Error occurred in fetchVacationsAgreementProcessStatusActionReset: ${error}`,
      )
    }
  },

  // VACATIONS AGRREMENT PROCESS STATUS COLLABORATOR
  // GET
  async fetchVacationsAgreementProcessStatusAction({ commit }, payload) {
    let url = `${BASE}/co/planning/api/v2/vacationAgreementProcess/status`
    if (payload) {
      url = `${BASE}/co/planning/api/v2/vacationAgreementProcess/status?filter[year]=${payload.year}`
    }
    try {
      // commit('UPDATE_VACATION_AGREEMENT_PROCESS_STATUS', null)
      const res = await axios.get(url)
      res.data.year = payload.year
      commit('UPDATE_VACATION_AGREEMENT_PROCESS_STATUS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // RESET PENDING NOTIFICATION (FOR COLLABORATOR ROLE)
  // GET
  async resetPendingQuarterlyNotificationCollaboratorState({ commit }) {
    try {
      await commit('RESET_PENDING_QUARTERLY_NOTIFICATION_FOR_COLLABORATOR')
    } catch (error) {
      throw new Error(
        `API Error occurred in resetPendingQuarterlyNotificationCollaboratorState: ${error}`,
      )
    }
  },
  // QUARTERLY PENDING NOTIFICATION (FOR COLLABORATOR ROLE)
  // GET
  async fetchPendingQuarterlyNotificationCollaboratorAction(
    { commit },
    payload,
  ) {
    const { year, quarter } = payload
    const url = `${BASE}/co/planning/api/notifications/quarterlyByCollaborator?filter[year]=${year}&filter[quarter]=${quarter}`
    try {
      // commit('UPDATE_PENDING_QUARTERLY_NOTIFICATION_FOR_COLLABORATOR', [])
      const response = await axios.get(url)
      if (response.data.length > 0)
        commit(
          'UPDATE_PENDING_QUARTERLY_NOTIFICATION_FOR_COLLABORATOR',
          response.data[0],
        )
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // VIEW PLANNINGS RELATED TO QUARTERLY NOTIFICATION (FOR COLLABORATOR ROLE)
  // POST

  async viewQuarterlyNotificationByCollaboratorAction({ commit }, payload) {
    try {
      commit('UPDATE_QUARTERLY_NOTIFICATIONS_BY_COLLABORATOR', {
        proposals: [],
      })
      const res = await axios.post(
        `${BASE}/co/planning/api/notifications/viewQuarterlyByCollaborator`,
        payload,
      )
      if (res.data.success) {
        commit(
          'UPDATE_QUARTERLY_NOTIFICATIONS_BY_COLLABORATOR',
          res.data.aggregates,
        )
      }
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/planning/api/notifications/viewQuarterlyByCollaborator: ${error}`,
      )
    }
  },
  // HOME RESPONSIBLE - RESET TotalCexHoursTeamContractType
  // RESET
  async resetTotalCexHoursTeamContractType(store) {
    store.commit('UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE', [])
  },

  // HOME RESPONSIBLE - totalCexHoursTeamContractType
  // GET TOTAL CEX HOURS TEAM CONTRACT TYPE
  async fetchTotalCexHoursTeamContractType(store, params) {
    // store.commit('UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE', null)
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/totalCexHoursTeamContractType?filter[teamUuid]=${params.teamUuid}&filter[personnelDivision]=${params.personnelDivision}&filter[startDate]=${params.startDate}&filter[endDate]=${params.endDate}`,
      )
      const responseObject = { ...res.data, teamName: params.teamName }
      store.commit('UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE', responseObject)
      return responseObject
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/planning/api/v2/planning/totalCexHoursTeamContractType',
      )
    }
  },
  // HOME DIRECTOR - totalCexHoursTeamContractTypeByDirector
  // GET TOTAL CEX HOURS TEAM CONTRACT TYPE
  async fetchTotalCexHoursTeamContractTypeByDirector(store, params) {
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/totalCexHoursTeamContractType?filter[teamUuid]=${params.teamUuid}&filter[personnelDivision]=${params.personnelDivision}&filter[startDate]=${params.startDate}&filter[endDate]=${params.endDate}`,
      )
      const responseObject = { ...res.data, teamName: params.teamName }
      store.commit(
        'UPDATE_TOTAL_CEX_HOURS_TEAM_CONTRACT_TYPE_BY_DIRECTOR',
        responseObject,
      )
      return responseObject
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/planning/api/v2/planning/totalCexHoursTeamContractType',
      )
    }
  },
  // HOME RESPONSIBLE - FETCH PENDING WEEKLY NOTIFICATIONS
  // GET
  async fetchAlertPendingWeeklyNotifications(store) {
    store.commit('UPDATE_ALERT_PENDING_WEEKLY_NOTIFICATIONS', null)
    const url = `${BASE}/co/planning/api/notifications/alertPendingWeeklyNotifications`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_ALERT_PENDING_WEEKLY_NOTIFICATIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // HOME RESPONSIBLE - FETCH legalPlanningErrorsInQuarter
  // GET
  async fetchLegalPlanningErrorsInQuarter(store) {
    store.commit('UPDATE_LEGAL_PLANNING_ERRORS_IN_QUARTER', null)
    const url = `${BASE}/co/planning/api/v2/PlanningManager/legalPlanningErrorsInQuarter`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_LEGAL_PLANNING_ERRORS_IN_QUARTER', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // TIMEBASE REPORT
  // GET
  async fetchTimebaseReport(store, filters) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const response = await axios.get(
        `${BASE}/co/planning/api/v2/timebaseReport${queryString}`,
      )
      store.commit('UPDATE_TIMEBASE_REPORT', response.data)
    } catch (e) {
      throw Error(
        `API Error occurred at patch api/v2/timebaseReport${queryString}.`,
      )
    }
  },
  // HOME DIRECTOR - FETCH alertPendingWeeklyNotificationsByDirector
  // GET
  async fetchAlertPendingWeeklyNotificationsByDirector(store, params) {
    store.commit('ALERT_PENDING_WEEKLY_NOTIFICATIONS_BY_DIRECTOR', null)
    const url = `${BASE}/co/planning/api/notifications/alertPendingWeeklyNotificationsByDirector?filter[personnelDivision]=${params.personnelDivision}`
    try {
      const res = await axios.get(url)
      store.commit('ALERT_PENDING_WEEKLY_NOTIFICATIONS_BY_DIRECTOR', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // HOME DIRECTOR - FETCH legalPlanningErrorsInQuarterByDirector
  // GET
  async fetchLegalPlanningErrorsInQuarterByDirector(store, params) {
    store.commit('LEGAL_PLANNING_ERRORS_IN_QUARTER_BY_DIRECTOR', null)
    const url = `${BASE}/co/planning/api/v2/PlanningManager/legalPlanningErrorsInQuarterByDirector?filter[personnelDivision]=${params.personnelDivision}`
    try {
      const res = await axios.get(url)
      store.commit('LEGAL_PLANNING_ERRORS_IN_QUARTER_BY_DIRECTOR', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  // SITE TIME SLOTS INTERVALS - FETCH defaultTimeSlots
  // GET
  async fetchDefaultTimeSlotsAction(store, filters) {
    store.commit('UPDATE_DEFAULT_TIME_SLOTS', [])
    const url = `${BASE}/co/planning/api/v2/planning/defaultTimeSlots`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_DEFAULT_TIME_SLOTS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // SITE TIME SLOTS INTERVALS - FETCH siteTimeSlotsIntervals
  // GET
  async fetchSiteTimeSlotsIntervalsAction(store, filters) {
    const { personnelDivision } = filters
    store.commit('UPDATE_SITE_TIME_SLOTS_INTERVALS', [])
    const url = `${BASE}/co/planning/api/v2/planning/siteTimeSlotIntervals/${personnelDivision}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SITE_TIME_SLOTS_INTERVALS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SITE TIME SLOTS INTERVALS - FETCH specific siteTimeSlotsInterval by UUID
  // GET
  async fetchSiteTimeSlotsIntervalAction(store, filters) {
    const { uuid } = filters
    store.commit('UPDATE_SITE_TIME_SLOTS_INTERVAL', null)
    const url = `${BASE}/co/planning/api/v2/planning/siteTimeSlotInterval/${uuid}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SITE_TIME_SLOTS_INTERVAL', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SITE TIME SLOTS INTERVALS - FETCH active time slots for given date and site
  // GET
  async fetchActiveSiteTimeSlotsAction(store, filters) {
    store.commit('UPDATE_ACTIVE_SITE_TIME_SLOTS', [])
    const queryString = queryStringFilterBuilder(filters)
    const url = `${BASE}/co/planning/api/v2/planning/activeSiteTimeSlotsBySiteAndDate${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_ACTIVE_SITE_TIME_SLOTS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SITE TIME SLOTS INTERVALS - CREATE site interval time slots
  // POST
  async createSiteTimeSlotsIntervalAction(store, payload) {
    const url = `${BASE}/co/planning/api/v2/planning/siteTimeSlotIntervals`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SITE TIME SLOTS INTERVALS - UPDATE site interval time slots
  // PUT
  async updateSiteTimeSlotsIntervalAction(store, payload) {
    const url = `${BASE}/co/planning/api/v2/planning/siteTimeSlotIntervals`
    try {
      return await axios.put(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // FETCH REPORT SHIFT WORK ANALYSIS
  // GET
  async fetchReportShiftWorkAnalysis(store, filters) {
    store.commit('UPDATE_REPORT_SHIFT_WORK_ANALYSIS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/shiftWorkAnalysis${queryString}`,
      )
      store.commit('UPDATE_REPORT_SHIFT_WORK_ANALYSIS', res.data)
      return res
    } catch (e) {
      throw Error(
        `API Error occurred at patch api/v2/planning/shiftWorkAnalysis${queryString}.`,
      )
    }
  },

  // FETCH REPORT SITE TIME SLOT INTERVAL ANALYSIS
  // GET
  async fetchReportSiteTimeSlotIntervalAnalysis(store, filters) {
    store.commit('UPDATE_SITE_TIME_SLOT_INTERVAL_ANALYSIS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/timeSlotIntervalAnalysis${queryString}`,
      )
      store.commit('UPDATE_SITE_TIME_SLOT_INTERVAL_ANALYSIS', res.data)
      return res
    } catch (e) {
      throw Error(
        `API Error occurred at patch api/v2/planning/timeSlotIntervalAnalysis${queryString}.`,
      )
    }
  },

  // FETCH MY GAME HOURS DATA
  // GET
  async fetchMyGameHoursData(store, filters) {
    store.commit('UPDATE_MY_GAME_HOURS_DATA', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/planning/totalHoursMyGame${queryString}`,
      )
      store.commit('UPDATE_MY_GAME_HOURS_DATA', res.data.result)
      return res
    } catch (e) {
      throw Error(
        `API Error occurred at patch api/v2/planning/totalHoursMyGame${queryString}.`,
      )
    }
  },
}

// ///////////
// HELPER FUNCTIONS
// //////////

function getTemplateEventToUpdate(state, event) {
  const eventIndex = state.planningTemplatePeriods.findIndex(
    (e) => e.id === event.id,
  )
  return state.planningTemplatePeriods[eventIndex]
}

function formatTemplatePeriods(planningTemplate) {
  return planningTemplate
    .map((e) => {
      return e.planningTemplatePeriodsDto
    })
    .flat()
    .map((period) => {
      return {
        id: period.uuid,
        resourceId: period.collaboratorUuid,
        start: period.fromHour,
        end: period.toHour,
        overlap: false,
        hourTypologyUuid: period.hourTypologyUuid,
        color: period.color,
        hourTypology: period.abbreviation,
        workDay: period.dayOfWeek,
      }
    })
}
function formatTeamWeekPlanningEvents(teamWeekPlanning) {
  return teamWeekPlanning.map((period) => {
    return {
      id: period.planningModificationPeriodUuid,
      resourceId: period.collaboratorUuid,
      title: `${
        ((new Date(period.toHour) - new Date(period.fromHour)) / 36e5) % 24
      }h ${period.hourTypology.abbreviation}`,
      start: period.fromHour,
      end: period.toHour,
      overlap: false,
      hourTypologyUuid: period.hourTypology.uuid,
      hourTypology: period.hourTypology.abbreviation,
      color: period.hourTypology.color,
      workDay: period.date,
    }
  })
}
function formatCollaboratorMonthPlanningEvents(collaboratorMonthPlanning) {
  return collaboratorMonthPlanning.map((period) => {
    return {
      id: period.planningPeriodUuid,
      resourceId: period.collaboratorUuid,
      title: `${
        ((new Date(period.toHour) - new Date(period.fromHour)) / 36e5) % 24
      }h ${period.translation}`,
      start: period.fromHour,
      end: period.toHour,
      overlap: false,
      hourTypologyUuid: period.hourTypologyUuid,
      hourTypology: period.translation,
      color: period.hourTypologyColor,
      workDay: period.date,
    }
  })
}
function formatCollaboratorWeekPlanningModificationDifferences(
  collaboratorWeekPlanningModificationDifferences,
) {
  return collaboratorWeekPlanningModificationDifferences.map(
    (dayEvents, dayIndex) => {
      return dayEvents.map((period, index) => {
        return {
          id: index,
          resourceId: 1,
          title: `${
            ((new Date(period.toHour) - new Date(period.fromHour)) / 36e5) % 24
          }h ${period.translation}`,
          start: period.fromHour,
          end: period.toHour,
          color: period.color,
          hourTypology: period.translation,
          action: period.action,
          workDay: period.date,
        }
      })
    },
  )
}
