import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    leaveProcess: null,
    leaveReasons: [],
    pendingHrCodeLeaveProcesses: [],
    hrCodeLeaveRecords: [],
    hrCodeLeaveCats: [],
    openLeaveProcesses: null,
    notRehireableReasons: {},
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_LEAVE_PROCESS(state, leaveProcess) {
    state.leaveProcess = leaveProcess
  },
  UPDATE_LEAVE_REASONS(state, leaveReasons) {
    state.leaveReasons = leaveReasons
  },
  UPDATE_PENDING_HR_CODE_LEAVE_PROCESSES(state, pendingHrCodeLeaveProcesses) {
    state.pendingHrCodeLeaveProcesses = pendingHrCodeLeaveProcesses
  },
  UPDATE_HR_CODE_LEAVE_RECORDS(state, hrCodeLeaveRecords) {
    state.hrCodeLeaveRecords = hrCodeLeaveRecords
  },
  UPDATE_HR_CODE_LEAVE_CATS(state, cats) {
    state.hrCodeLeaveCats = cats
  },
  UPDATE_OPEN_LEAVE_PROCESSES(state, openLeaveProcesses) {
    state.openLeaveProcesses = openLeaveProcesses
  },
  UPDATE_NOT_REHIREABLE_REASONS(state, notRehireableReasons) {
    state.notRehireableReasons = notRehireableReasons
  },
  RESET_LEAVE_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  // LEAVE PROCESS
  // GET
  async fetchLeaveProcess(store, processUuid) {
    try {
      store.commit('UPDATE_LEAVE_PROCESS', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/leaveProcesses/${processUuid}`,
      )
      store.commit('UPDATE_LEAVE_PROCESS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/leaveProcesses/${processUuid}`,
      )
    }
  },
  // OPEN LEAVE PROCESSES
  // GET
  async fetchOpenLeaveProcesses(store, filters = {}) {
    store.commit('UPDATE_OPEN_LEAVE_PROCESSES', null)
    const filtersArr = joinFilters(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/leaveProcesses.common/unfinishedStatus${queryString}`,
      )
      store.commit('UPDATE_OPEN_LEAVE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in es/api/v2/leaveProcesses.common/unfinishedStatus.',
      )
    }
  },
  // LEAVE REASONS
  // GET
  async fetchLeaveReasons(store, collaboratorUuid) {
    store.commit('UPDATE_LEAVE_REASONS', [])
    const queryParams = collaboratorUuid
      ? `?filter[collaboratorUuid]=${collaboratorUuid}`
      : ''
    const url = `${BASE}/co/api/v2/applicableLeaveReasons${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_LEAVE_REASONS', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // HR CODE PENDING PROCESSES
  // GET
  // GOODBYE ASYNC/AWAIT
  fetchPendingHrCodeLeaveProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_HR_CODE_LEAVE_PROCESSES', [])
    const filtersArr = joinFiltersHrCode(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE}/co/api/v2/leaveProcesses.common/pendingHRManagement${queryString}`,
        )
        .then((res) => {
          store.commit('UPDATE_PENDING_HR_CODE_LEAVE_PROCESSES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // HR CODE LEAVE NUMBERS
  // GET
  async fetchHrCodeLeaveRecords(store, { payload, txtWanted }) {
    let url = ''
    switch (txtWanted) {
      case 'numbers':
        store.commit('UPDATE_HR_CODE_LEAVE_RECORDS', [])
        url = 'downloadHrCodeNumbers'
        break
      case 'cats':
        store.commit('UPDATE_HR_CODE_LEAVE_CATS', [])
        url = 'downloadCats'
        break
      default:
        store.commit('UPDATE_HR_CODE_LEAVE_RECORDS', [])
        url = 'downloadList'
    }
    const queryString =
      payload.processesUuids.length > 0
        ? `?filter[leaveProcessesUuid]=${payload.processesUuids}&filter[notShowExecutedAndMarkedRehireability]=${payload.notShowExecutedAndMarkedRehireability}`
        : `?filter[notShowExecutedAndMarkedRehireability]=${payload.notShowExecutedAndMarkedRehireability}`
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/leaveProcesses.common/${url}${queryString}`,
      )
      switch (txtWanted) {
        case 'numbers':
          store.commit('UPDATE_HR_CODE_LEAVE_RECORDS', res.data)
          break
        case 'cats':
          store.commit('UPDATE_HR_CODE_LEAVE_CATS', res.data.catsData)
          break
        default:
          store.commit('UPDATE_HR_CODE_LEAVE_RECORDS', res.data)
      }
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/leaveProcesses.common/${url}${queryString}`,
      )
    }
  },
  // NOT REHIREABLE REASONS
  async fetchNotRehireableReasons(store) {
    try {
      store.commit('UPDATE_NOT_REHIREABLE_REASONS', {})
      const res = await axios.get(`${BASE}/co/api/v2/notRehireableReasons/all`)
      store.commit('UPDATE_NOT_REHIREABLE_REASONS', res.data.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/api/v2/notRehireableReasons/all.',
      )
    }
  },
  // ///////
  // POSTS
  // //////

  // NEW LEAVE
  // POST
  async startLeaveProcess(store, { payload, leaveReasonName }) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.startLeaveProcess.${leaveReasonName}`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.startLeaveProcess.${leaveReasonName}.`,
      )
    }
  },

  // NEW LEAVE OF ABSENCE
  // POST
  async startLeaveOfAbsenceProcess(store, { payload, leaveReasonName }) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.startLeaveOfAbsence.${leaveReasonName}`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.startLeaveOfAbsence.${leaveReasonName}.`,
      )
    }
  },

  // UPDATE REHIREABILITY
  // POST
  async updateRehireability(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.updateRehireability`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.updateRehireability.`,
      )
    }
  },
  // UPDATE HR MANAGEMENT
  // POST
  async updateHRManagement(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.updateHRManagement`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.updateHRManagement.`,
      )
    }
  },
  // CANCEL LEAVE PROCESS
  // POST
  async cancelLeaveProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.cancelLeaveProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.cancelLeaveProcess.`,
      )
    }
  },
  // CANCEL LEAVE OF ABSENCE PROCESS
  // POST
  async cancelLeaveOfAbsenceProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.cancelLeaveOfAbsenceProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.cancelLeaveOfAbsenceProcess.`,
      )
    }
  },
  // EXTEND LEAVE OF ABSENCE
  // POST
  async extendLeaveOfAbsenceAction(store, payload) {
    const { command, leaveOfAbsenceType } = payload
    let path
    switch (leaveOfAbsenceType) {
      case 'VOLUNTARY_LEAVE':
        path = 'voluntary'
        break
      case 'MATERNITY_LEAVE':
        path = 'maternity'
        break
      case 'STUDY_LEAVE':
        path = 'study'
        break
      case 'FAMILY_CARE_LEAVE':
        path = 'familyCare'
        break
    }
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.extendLeaveOfAbsence.${path}`,
        command,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.extendLeaveOfAbsence.`,
      )
    }
  },
  // NO DEBT REPORT
  // GET
  async getNoDebtReport(store, filters = {}) {
    const filtersArr = joinFiltersHrCode(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      return await axios.get(
        `${BASE}/co/api/v2/contractManager/leave.noDebtReport${queryString}`,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.noDebtReport.`,
      )
    }
  },
}

function joinFilters(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  return filtersArr
}

function joinFiltersHrCode(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    filtersArr.push(`filter[${key}]=${filters[key]}`)
  })
  return filtersArr
}
