import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        bankAccountTypes: [],
        bankAccounts: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_ACCOUNT_TYPES(state, bankAccountTypes) {
        state.bankAccountTypes = bankAccountTypes
    },
    UPDATE_BANK_ACCOUNTS(state, bankAccounts) {
        state.bankAccounts = bankAccounts
    },
}

export const actions = {
    async fetchAccountTypes(store) {
        try {
            store.commit('UPDATE_ACCOUNT_TYPES', [])

            const url = `${BASE}/co/api/v2/bankAccountTypes`
            const res = await axios.get(url)
            store.commit('UPDATE_ACCOUNT_TYPES', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/bankAccountTypes.')
        }
    },

    async fetchBankAccounts(store) {
        try {
            store.commit('UPDATE_BANK_ACCOUNTS', [])

            const url = `${BASE}/co/api/v2/bankAccounts`
            const res = await axios.get(url)
            store.commit('UPDATE_BANK_ACCOUNTS', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/bankAccounts.')
        }
    },
}