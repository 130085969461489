import { aggregates, urlQueryStringBuilder } from '@utils/constantHelpers'
import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    openProcesses: {},
    openProcess: null,
    identification: {
      identificationNumber: '',
      documentType: '',
    },
    agreementSalary: {},
    curriculumOrigins: [],
    resultExceptions: [],
    pendingHrCodeHiringProcesses: [],
    hrCodeHiringRecords: [],
    pendingHrCodeContractExtensionProcesses: [],
    verificationDocumentTokenData: {
      token: '',
      identificationNumber: '',
    },
    endOfContractDocument: null,
    notPassedTrialDocument: null,
    signaturePendingContractCollaborator: null,
    hireableWindows: [],
    hireableWindowsItem: {},
    collaboratorsPerContractType: null,
    pendingToSign: null,
    callingCDIFDProcesses: null,
    nextBusinessDate: null
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_NEXT_BUSINESS_DATE(state, nextBusinessDate) {
    state.nextBusinessDate = nextBusinessDate
  },

  UPDATE_OPEN_PROCESS(state, openProcess) {
    if ({}.hasOwnProperty.call(openProcess, 'exceptions')) {
      state.openProcess = null
    } else {
      state.openProcess = openProcess
    }
  },
  UPDATE_OPEN_PROCESSES(state, openProcesses) {
    state.openProcesses = openProcesses
  },
  UPDATE_CONTRACT_BASE_64(state, docName) {
    state.docName = docName
  },
  UPDATE_AGREED_SALARY(state, agreementSalary) {
    state.agreementSalary = agreementSalary
  },
  UPDATE_CURRICULUM_ORIGINS(state, curriculumOrigins) {
    state.curriculumOrigins = curriculumOrigins
  },
  UPDATE_IDENTIFICATION_NUMBER(state, identificationNumber) {
    state.identification.identificationNumber = identificationNumber
  },
  UPDATE_DOCUMENT_TYPE(state, documentType) {
    state.identification.documentType = documentType
  },
  UPDATE_PENDING_HR_CODE_HIRING_PROCESSES(state, pendingHrCodeHiringProcesses) {
    state.pendingHrCodeHiringProcesses = pendingHrCodeHiringProcesses
  },
  UPDATE_HR_CODE_HIRING_RECORDS(state, hrCodeHiringRecords) {
    state.hrCodeHiringRecords = hrCodeHiringRecords
  },
  UPDATE_PENDING_HR_CODE_CONTRACT_EXTENSION_PROCESSES(
    state,
    pendingHrCodeContractExtensionProcesses,
  ) {
    state.pendingHrCodeContractExtensionProcesses =
      pendingHrCodeContractExtensionProcesses
  },
  UPDATE_VERIFICATION_DOCUMENT_TOKEN_DATA(
    state,
    { identificationNumber, token },
  ) {
    state.verificationDocumentTokenData.identificationNumber =
      identificationNumber
    state.verificationDocumentTokenData.token = token
  },
  UPDATE_END_OF_CONTRACT_DOCUMENT(state, endOfContractDocument) {
    state.endOfContractDocument = endOfContractDocument
  },
  UPDATE_NOT_PASSED_TRIAL_DOCUMENT(state, notPassedTrialDocument) {
    state.notPassedTrialDocument = notPassedTrialDocument
  },
  RESET_CONTRACT_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR(
    state,
    signaturePendingContractCollaborator,
  ) {
    state.signaturePendingContractCollaborator =
      signaturePendingContractCollaborator
  },
  UPDATE_HIREABLE_WINDOWS(state, hireableWindows) {
    state.hireableWindows = hireableWindows
  },
  UPDATE_HIREABLE_WINDOW_ITEM(state, hireableWindowsItem) {
    state.hireableWindowsItem = hireableWindowsItem
  },
  UPDATE_COLLABORATORS_PER_CONTRACT_TYPE(state, collaboratorsPerContractType) {
    state.collaboratorsPerContractType = collaboratorsPerContractType
  },
  UPDATE_PENDING_TO_SIGN(state, pendingToSign) {
    state.pendingToSign = pendingToSign
  },
  UPDATE_CALLING_CDIFD_PROCESSES(state, callingCDIFDProcesses) {
    state.callingCDIFDProcesses = callingCDIFDProcesses
  },
}

export const actions = {
  // OPEN PROCESSES
  // GET
  async fetchOpenProcess(store, options = {}) {
    // store.commit('UPDATE_OPEN_PROCESS', null)
    let url = `${BASE}/co/api/v2/contractProcesses.common/${options.processUuid}`
    if (options.personnelDivision) {
      // Review
      url = `${BASE}/co/api/v2/sites/${options.personnelDivision}/contractProcesses.common/${options.processUuid}`
    }
    if (options.withIndex) {
      url = `${url}?withIndex=true`
    }
    if (options.withProcessRoadmap) {
      url = `${url}?withProcessRoadmap=true`
    }
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_OPEN_PROCESS', res.data)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error.message}`)
    }
  },
  // CLEAR OPEN PROCESSES
  async resetOpenProcesses(store) {
    store.commit('UPDATE_OPEN_PROCESSES', {})
  },
  // OPEN PROCESSES
  // GET
  async fetchOpenProcesses(store, payload = {}) {
    store.commit('UPDATE_OPEN_PROCESSES', {})
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/contractProcesses.common${urlQueryStringBuilder(
          payload,
        )}`,
      )
      store.commit('UPDATE_OPEN_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/contractProcesses.common${urlQueryStringBuilder(
          payload,
        )}.`,
      )
    }
  },
  // OPEN PROCESSES OF SITE
  // GET
  async fetchSiteOpenProcesses(store, payload = {}) {
    store.commit('UPDATE_OPEN_PROCESSES', {})
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/sites/${
          payload.personnelDivision
        }/contractProcesses.common${urlQueryStringBuilder(payload)}`,
      )
      store.commit('UPDATE_OPEN_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/sites/${
          payload.personnelDivision
        }/contractProcesses.common${urlQueryStringBuilder(payload)}.`,
      )
    }
  },
  // CONTRACT PDF GENERATION
  // GET
  async fetchContractInBase64(store, options = {}) {
    try {
      store.commit('UPDATE_CONTRACT_BASE_64', {})
      const URL = `${BASE}/co/api/v2/contracts/fetchPdfBase64/${options.processUuid}`
      const res = await axios.get(URL)
      store.commit('UPDATE_CONTRACT_BASE_64', res.data.name)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contracts/fetchPdfBase64/${options.processUuid}`,
      )
    }
  },

  // AGREED SALARY
  // GET
  async fetchAgreementSalary(store, code) {
    try {
      store.commit('UPDATE_AGREED_SALARY', {})
      const res = await axios.get(`${BASE}/api/v2/parameters/code/${code}`)
      store.commit('UPDATE_AGREED_SALARY', res.data)
    } catch (error) {
      throw new Error(`API Error occurred at /api/v2/parameters/code/${code}`)
    }
  },
  // GET CV SOURCES
  async fetchCurriculumOrigin(store) {
    try {
      const res = await axios.get(`${BASE}/co/api/v2/curriculumOrigins`)
      store.commit('UPDATE_CURRICULUM_ORIGINS', res.data)
    } catch (error) {
      throw new Error('API Error occurred in /api/v2/curriculumOrigins/.')
    }
  },

  // PENDING HR CODE HIRING PROCESSES
  // GET
  async fetchPendingHrCodeHiringProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_HR_CODE_HIRING_PROCESSES', [])
    let queryString = ''
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((key) => {
      if (filters[key]) {
        if (key === 'dateInterval') {
          const newKey = `startDateInterval=${filters[key][0]}&endDateInterval=${filters[key][1]}`
          return (queryString += `&${newKey}`)
        } else return (queryString += `&${key}=${filters[key]}`)
      }
    })
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/contractManager/hiring.updateHrCode?${queryString}`,
      )
      store.commit('UPDATE_PENDING_HR_CODE_HIRING_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in es/api/v2/contractManager/hiring.updateHrCode' +
          queryString,
      )
    }
  },
  // HR CODE HIRING RECORDS
  // GET
  async fetchHrCodeHiringRecords(store, filters = {}) {
    store.commit('UPDATE_HR_CODE_HIRING_RECORDS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        return filtersArr.push(`${key}=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/contractManager/hiring.hrCodeRecords${queryString}`, // it is necessary to attach filters date, etc to URL
      )
      store.commit('UPDATE_HR_CODE_HIRING_RECORDS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/contractManager/hiring.hrCodeRecords${queryString}`,
      )
    }
  },
  // PENDING HR CODE CONTRACT EXTENSION PROCESSES
  // GET
  async fetchPendingHrCodeContractExtensionProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_HR_CODE_CONTRACT_EXTENSION_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/contractManager/extension.updateHrCodeProcess${queryString}`,
      )
      store.commit(
        'UPDATE_PENDING_HR_CODE_CONTRACT_EXTENSION_PROCESSES',
        res.data.data,
      )
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/api/v2/contractManager/extension.updateHrCodeProcess' +
          queryString,
      )
    }
  },
  // HR CODE CONTRACT EXTENSION RECORDS
  // GET
  async fetchHrCodeContractExtensionRecords(store, payload) {
    const queryString =
      payload.processesUuids.length > 0
        ? `?filter[extensionProcessesUuid]=${payload.processesUuids}`
        : ''
    try {
      return await axios.get(
        `${BASE}/co/api/v2/contractManager/extension.updateHrCodeProcess/downloadList${queryString}`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/extension.updateHrCodeProcess/downloadList${queryString}`,
      )
    }
  },
  // END OF CONTRACT DOCUMENT
  // GET
  async fetchEndOfContractDocumentAction(store, collaboratorUuid) {
    try {
      store.commit('UPDATE_END_OF_CONTRACT_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/contracts/completion.notice.document/collaborator/${collaboratorUuid}`,
      )
      store.commit('UPDATE_END_OF_CONTRACT_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contracts/completion.notice.document/collaborator/${collaboratorUuid}`,
      )
    }
  },
  // NOT PASSED TRIAL DOCUMENT
  // GET
  async fetchNotPassedTrialDocumentAction(store, collaboratorUuid) {
    try {
      store.commit('UPDATE_NOT_PASSED_TRIAL_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/contracts/not.passed.trial.notice.document/collaborator/${collaboratorUuid}`,
      )
      store.commit('UPDATE_NOT_PASSED_TRIAL_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contracts/not.passed.trial.notice.document/collaborator/${collaboratorUuid}`,
      )
    }
  },

  // //////
  // POST
  // //////

  // HIRING
  // CHECK ID before start Hiring
  async checkId({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.checkId`,
        payload,
      )
      commit(
        'UPDATE_IDENTIFICATION_NUMBER',
        payload.identificationDto.personalIdentificationNumber,
      )
      commit(
        'UPDATE_DOCUMENT_TYPE',
        payload.identificationDto.identificationDocumentType,
      )
      return response
    } catch (e) {
      throw Error('API Error occurred in api/checkId.')
    }
  },
  // POST ID + TOKEN
  async postIdToken({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.verifyIdentificationDocumentAndToken`,
        payload,
      )
      if (response.data.success) {
        commit('UPDATE_VERIFICATION_DOCUMENT_TOKEN_DATA', {
          identificationNumber: payload.verifyDocumentTokenDto.identificationNumber,
          token: payload.verifyDocumentTokenDto.token,
        })
        commit(
          'collaborator/UPDATE_COLLABORATOR',
          response.data.aggregates.collaborator,
          { root: true },
        )

          commit(
              'contract/UPDATE_CONTRACT_TYPE',
              response.data.aggregates.contractType,
              { root: true }
          )
      }
      return response
    } catch (e) {
      throw Error(
        `API Error occurred in /api/v2/contractManager/hiring.verifyIdentificationDocumentAndToken.`,
      )
    }
  },

  // NEW HIRING
  async startHiringProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.startHiringProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at api/v2/contractManager/hiring.startHiringProcess.`,
      )
    }
  },

  // RECTIFY HIRING PROCESS
  async editRectifyHiringProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.rectify`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at api/v2/contractManager/hiring.startHiringProcess.`,
      )
    }
  },

  // EDIT HIRING PROCESS
  async editHiringProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.edit`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at api/v2/contractManager/hiring.edit.`)
    }
  },

  // VALIDATE PROCESS
  async validateProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/common.validateProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/common.validateProcess.`,
      )
    }
  },

  // ABORT HIRING CONTRACT PROCESS
  async abortProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.abortHiringProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/hiring.abortHiringProcess.`,
      )
    }
  },

  // CANCEL HIRING CONTRACT PROCESS
  async cancelContractProcess(store, payload) {
    const aggregate =
      aggregates[payload.processType] === undefined
        ? 'hiring'
        : aggregates[payload.processType]
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/${aggregate}.cancelProcess`,
        payload.command,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/${aggregate}.cancelProcess.`,
      )
    }
  },

  // sendEmail to Collaborator with link to enter personal in first steps of hiring contract process
  async sendEmailCollaboratorStart(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.sendEmailCollaboratorToken`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/hiring.sendEmailCollaboratorToken.`,
      )
    }
  },

  // POST PERSONAL AND ADDRESS DATA, HIRING PROCESS
  async insertCollaboratorData(store, command) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.insertPersonalData`,
        command,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/hiring.insertPersonalData`,
      )
    }
  },
  // POST ASSIGN HR CODE
  async assignHrCode(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.assignIndividualHrCode`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/hiring.assignIndividualHrCode`,
      )
    }
  },
  // POST ASSIGN MULTIPLE HR CODE
  async assignMultipleHrCode(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.assignMultipleHrCode`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/hiring.assignMultipleHrCode`,
      )
    }
  },

  // SIGN PROCESS IN PAPER
  async signOnPaperAction(store, payload) {
    let path
    switch (payload.processType) {
      case 'HIRING_CONTRACT_PROCESS_COL':
        path = 'hiring'
        break
      case 'CONVERSION_CONTRACT_PROCESS_COL':
        path = 'conversion'
        break
      case 'REHIRING_CONTRACT_PROCESS_COL':
        path = 'rehiring'
        break
      case 'EXTENSION_CONTRACT_PROCESS_COL':
        path = 'extension'
        break
    }

    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/${path}.signOnPaper`,
        payload.command,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contractManager/${path}.signOnPaper`,
      )
    }
  },

  // REHIRING
  // START REHIRING PROCESS

  async startRehiringProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.startRehiringProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/hiring.startRehiringProcess.`,
      )
    }
  },

  // LEAVE OF ABSENCE RE-ENTRY
  async leaveOfAbsenceReEntryAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/leave.startRehiringLeaveOfAbsence`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/leave.startRehiringLeaveOfAbsence.`,
      )
    }
  },

  // CONTRACT EXTENSION
  // POST
  async extendContractAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/extension.startExtensionProcess`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /co/api/v2/contractManager/extension.startExtensionProcess: ${error}`,
      )
    }
  },

    async editExtensionProcessAction(store, payload) {
        try {
            return await axios.post(
                `${BASE}/co/api/v2/contractManager/extension.edit`,
                payload,
            )
        } catch (error) {
            throw new Error(
                `API Error ocurred in /es/api/v2/contractManager/extension.edit: ${error}`,
            )
        }
    },

  // CANCEL CONTRACT EXTENSION
  // POST
  async cancelContractExtensionAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/extension.cancelExtensionProcess`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /co/api/v2/contractManager/extension.cancelExtensionProcess: ${error}`,
      )
    }
  },
  // SEND CONTRACT DOCUMENT
  // POST
  async sendProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/common.sendContract`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /co/api/v2/contractManager/common.sendContract: ${error}`,
      )
    }
  },

  // UPDATE MANAGED HIRING PROCESSES
  // POST
  async updateManagedHiringProcesses(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/hiring.updateHrCode.management`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/hiring.updateHrCode.management.`,
      )
    }
  },
  // HR CODE CONTRACT EXTENSION RECORDS
  // POST
  async updateManagedContractExtensionProcesses(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/extension.updateHrCodeProcess.management`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/extension.updateHrCodeProcess.management`,
      )
    }
  },
  // SIGNATURE PENDING CONTRACT PROCESSES COLLABORATOR
  // GET
  async fetchSignaturePendingContractCollaborator(store) {
    try {
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/contractManager/collaborator.signaturePendingContractProcesses`,
      )
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contractManager/collaborator.signaturePendingContractProcesses`,
      )
    }
  },
  // GET TIME WINDOWS LIST
  // GET
  async fetchHireableWindows(store) {
    try {
      // store.commit('UPDATE_HIREABLE_WINDOWS', null)
      const res = await axios.get(`${BASE}/co/api/v2/hireableWindow`)
      store.commit('UPDATE_HIREABLE_WINDOWS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${BASE}/co/api/v2/hireableWindow`)
    }
  },
  // HIREABLE WINDOW CREATE
  // POST
  async hireableWindowCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/hireableWindow/create`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /co/api/v2/hireableWindow/create`)
    }
  },
  // HIREABLE WINDOW
  // GET
  async fetchHireableWindowItemAction(store, uuid) {
    if (state.hireableWindows && state.hireableWindows.length > 0) {
      try {
        const item = state.hireableWindows.filter(
          (item) => item.uuid === uuid,
        )[0]
        item.startDate = `${item.startMonth}-${item.startDay}`
        item.endDate = `${item.endMonth}-${item.endDay}`
        store.commit('UPDATE_HIREABLE_WINDOW_ITEM', item)
      } catch (error) {
        throw new Error('API Error occurred in fetchHireableWindowItemAction')
      }
    } else {
      try {
        const res = await axios.get(
          `${BASE}/co/api/v2/hireableWindow?filter[uuid]=${uuid}`,
        )
        const item = res.data[0]
        res.data[0].startDate = `${res.data[0].startMonth}-${res.data[0].startDay}`
        res.data[0].endDate = `${res.data[0].endMonth}-${res.data[0].endDay}`
        store.commit('UPDATE_HIREABLE_WINDOW_ITEM', item)
      } catch (error) {
        throw new Error(
          `API Error occurred in ${BASE}/co/api/v2/hireableWindow?filter[uuid]`,
        )
      }
    }
  },
  // HIREABLE WINDOW UPDATE
  // PUT
  async hireableWindowUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/hireableWindow/update`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /co/api/v2/hireableWindow/create`)
    }
  },
  // HOME RESPONSIBLE - GET COLLABORATORS PER CONTRACT TYPE
  // GET
  async fetchCollaboratorsPerContractType(store) {
    try {
      store.commit('UPDATE_COLLABORATORS_PER_CONTRACT_TYPE', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/collaboratorsPerContractType`,
      )
      store.commit('UPDATE_COLLABORATORS_PER_CONTRACT_TYPE', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /api/v2/collaboratorsPerContractType',
      )
    }
  },
  // HOME RESPONSIBLE - GET pendingToSign
  // GET
  async fetchPendingToSign(store) {
    try {
      store.commit('UPDATE_PENDING_TO_SIGN', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/contractProcesses.common/alert/pendingToSign`,
      )
      store.commit('UPDATE_PENDING_TO_SIGN', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /api/v2/contractProcesses.common/alert/pendingToSign',
      )
    }
  },
  // REACTIVATE COLLABORATOR
  // POST
  async reactivateCollaboratorAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/common.reactivateContract`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/common.reactivateContract`,
      )
    }
  },
  // FETCH CDI CALLING OPEN PROCESSES
  // GET
  async fetchCallDiscontinuousFixedProcess(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/contractManager/callDiscontinuousFixedProcess.list${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CALLING_CDIFD_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // START CALLING CDI FD PROCESS
  // POST
  async startCallingProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/call.startCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/call.startCallProcess`,
      )
    }
  },
  // UPDATE CALLING CDI FD PROCESS
  // POST
  async updateCallingProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/call.updateCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/call.updateCallProcess`,
      )
    }
  },
  // CANCEL CALLING CDI FD PROCESS
  // POST
  async cancelCallingProcess({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/call.cancelCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/call.cancelCallProcess`,
      )
    }
  },
  // FETCH (show) CDI CALLING 1 OPEN PROCESSES
  // GET
  async fetchCallDiscontinuousFixedProcessData(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/contractManager/callDiscontinuousFixedProcess.show${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CALLING_CDIFD_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  // Contract date management
  async fetchNextBusinessDate(store, filter) {
    const siteCode = filter.siteCode
    const timestamp = filter.timestamp
    const url = `${BASE}/co/api/v2/businessDay/${siteCode}/${timestamp}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_NEXT_BUSINESS_DATE', res.data.date)
      return res.data.date
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
}
