import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL
const defaultRecordWorkday = { status: null, entryDate: null }

const getDefaultState = () => {
  return {
    recordWorkday:defaultRecordWorkday,
    recordsWorkdayData: []
  }
}

export const state = getDefaultState()

export const mutations = {
  UPDATE_RECORD_WORKDAY(state, status) {
    state.recordWorkday = status
  },
  UPDATE_RECORDS_WORKDAY_DATA(state, recordsWorkdayData) {
    state.recordsWorkdayData = recordsWorkdayData
  },
}

export const actions = {
  async fetchRecordWorkday(store) {
    try {
      store.commit('UPDATE_RECORD_WORKDAY', defaultRecordWorkday)
      const res = await axios.get(
        `${BASE}/co/planning/api/v2/recordWorkDay/recordWorkDayStatus`,
      )
      store.commit('UPDATE_RECORD_WORKDAY', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/planning/api/v2/recordWorkDay/recordWorkDayStatus',
      )
    }
  },

  async updateRecordWorkDay(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/planning/api/v2/recordWorkDay/update`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/planning/api/v2/recordWorkDay/update.`,
      )
    }
  },

  async fetchRecordsWorkdayData(store, filters) {
    store.commit('UPDATE_RECORDS_WORKDAY_DATA', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/planning/api/v2/recordWorkDay/recordWorkDayReport${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_RECORDS_WORKDAY_DATA', res.data)
      return res.data
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },

  async updateWorkdayManagement(store, payload) {
    const url = `${BASE}/co/planning/api/v2/recordWorkDay/updateWorkdayManagement`
    try {
      return await axios.post(url, payload)
    } catch (e) {
      throw Error(`API Error occurred at ${url}.`)
    }
  },
}
