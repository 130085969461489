import store from '@state/store'
import { siteClearance } from '@utils/clearanceHelpers'
import planningRoutes from './planningRoutes'

export default [
  ...planningRoutes,
  {
    path: '/',
    beforeResolve: (to, from, next) => {
      store.dispatch('collaborator/RESET_COLLABORATOR_STATE()')
      next() // decide view to redirect when user access directly without verification
    },
    redirect: (to) => {
      const actorRoles = store.state.auth.actorRoles
      if (!actorRoles) return '/logout'

      const site = []
      // eslint-disable-next-line array-callback-return
      actorRoles.map((e) => {
        if (e.site && !site.includes(e.site.personnelDivision)) {
          site.push(e.site.personnelDivision)
        }
      })

      const isAuthorized = (rolesArr, clearanceArr) => {
        return rolesArr.some((element) => {
          return clearanceArr.some((e) => e === element.role.code)
        })
      }
      if (
        isAuthorized(actorRoles, siteClearance.admin) ||
        isAuthorized(actorRoles, siteClearance.directorCoach) ||
        isAuthorized(actorRoles, siteClearance.multicenterLeader)
      ) {
        return { name: 'colombia-map' }
      } else if (isAuthorized(actorRoles, siteClearance.lawyer)) {
        return {
          name: 'home-legal',
          params: { personnelDivision: site[0] },
        }
      } else if (
        isAuthorized(actorRoles, siteClearance.director) ||
        isAuthorized(actorRoles, siteClearance.organizer)
      ) {
        return {
          name: 'home-site',
          params: { personnelDivision: site[0] },
        }
      } else if (isAuthorized(actorRoles, siteClearance.responsible)) {
        return {
          name: 'home-site-responsible',
          params: { personnelDivision: site[0] },
        }
      } else if (
        isAuthorized(actorRoles, siteClearance.collaborator) ||
        isAuthorized(actorRoles, siteClearance.responsibleCollaborator)
      ) {
        return {
          name: 'home-site-collaborator',
          params: { personnelDivision: site[0] },
        }
      }
    },
    meta: {
      authRequired: true,
      authorize: [],
    },
  },
  // ///////////
  // HOME ROUTES
  // ///////////
  {
    path: '/map',
    name: 'colombia-map',
    component: () => import('@views/home/colombia-map.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.directorCoach,
        ...siteClearance.multicenterLeader,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/home/0',
    name: 'home-admin',
    component: () => import('@views/home/home-admin.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/co/:personnelDivision/home/1',
    name: 'home-site',
    component: () => import('@views/home/site/home-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.admin,
        ...siteClearance.organizer,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/home/2',
    name: 'home-site-responsible',
    component: () => import('@views/home/site/home-site-responsible.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.responsible,
    },
  },
  {
    path: '/co/:personnelDivision/home/3',
    name: 'home-site-collaborator',
    component: () => import('@views/home/site/home-site-collaborator.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/home/4',
    name: 'home-legal',
    component: () => import('@views/home/home-legal.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.lawyer,
    },
  },
  /// ////////////////////////////
  // RECORD WORKDAY REPORTING
  /// ////////////////////////////
  {
    path: '/co/:personnelDivision/records-workday',
    name: 'records-workday',
    component: () => import('@/src/router/views/workday/records-workday.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.admin,
        ...siteClearance.responsible,
      ],
    },
  },
  // ///////////////////
  // ISSUES MANAGEMENT && ISSUES VIEW
  // ///////////////////
  {
    path: '/issues-management',
    name: 'issues-management',
    component: () => import('@views/admin/issues/issues-management.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/co/issues',
    name: 'issues',
    component: () => import('@views/issues/issues-page.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  // ///////////////////
  // COLLABORATOR ROUTES
  // ///////////////////
  {
    path: '/collaborators',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.lawyer],
    },
    children: [
      {
        path: '',
        name: 'collaborator-list',
        component: () =>
          import('@views/collaborator/collaborator-list/collaborator-list.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: 'deletedCollaborators',
        name: 'collaborator-list-deleted',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-deleted.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: 'extraction',
        name: 'collaborators-extraction',
        component: () =>
          import(
            '@/src/router/views/collaborator/extraction/extraction-hr.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  {
    path: '/co/:personnelDivision/collaborators',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-list-site',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'deletedCollaborators',
        name: 'collaborator-list-deleted-site',
        component: () =>
          import(
            '@views/collaborator/collaborator-list/collaborator-list-deleted-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'extraction',
        name: 'collaborators-extraction-site',
        component: () =>
          import('@views/collaborator/extraction/extraction-site.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'data-center',
        name: 'collaborators-data-center',
        component: () =>
          import('@views/collaborator/data-center/data-center.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':collaboratorUuid',
        name: 'collaborator-site-info',
        component: () =>
          import('@views/collaborator/collaborator-info/collaborator-info.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
    ],
  },
  {
    path: '/co/:personnelDivision/:collaboratorUuid/selectRehireable/:processUuid',
    name: 'select-rehireable',
    component: () => import('@views/leave/leave-form/leave-form.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/collaborator/:collaboratorUuid/document/contract/:contractStartDate',
    name: 'site-collaborator-contract-basic-document',
    component: () =>
      import('@views/contract/document/contract-basic-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/collaborator/:collaboratorUuid/document/fullContract/:contractStartDate',
    name: 'site-collaborator-contract-full-document',
    component: () =>
      import('@views/contract/document/contract-full-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
      ],
    },
  },
  {
    path: '/co/collaborator/:collaboratorUuid/document/endOfContract',
    name: 'collaborator-end-of-contract-document',
    component: () =>
      import('@views/contract/document/end-of-contract-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/collaborator/:collaboratorUuid/document/endOfContract',
    name: 'site-collaborator-end-of-contract-document',
    component: () =>
      import('@views/contract/document/end-of-contract-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/collaborator/:collaboratorUuid/document/notPassedTrial',
    name: 'collaborator-not-passed-trial-document',
    component: () =>
      import('@views/contract/document/not-passed-trial-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/collaborator/:collaboratorUuid/document/notPassedTrial',
    name: 'site-collaborator-not-passed-trial-document',
    component: () =>
      import('@views/contract/document/not-passed-trial-document.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // /////////////////
  // DIRECTOR COACHES
  // /////////////////
  {
    path: '/directorCoaches',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
    children: [
      {
        path: '',
        name: 'director-coaches-list',
        component: () =>
          import('@views/admin/director-coach/director-coaches-list.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':collaboratorUuid/sites',
        name: 'director-coach-sites',
        component: () =>
          import('@views/admin/director-coach/director-coach-sites.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  // /////////////////
  // PARAMETERS ROUTES
  // /////////////////
  {
    path: '/parameters',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'parameter-list',
        component: () => import('@views/common/parameters/parameter-list.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'new',
        name: 'parameter-new',
        component: () => import('@views/common/parameters/parameter-new.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':id',
        name: 'parameter-edit',
        component: () => import('@views/common/parameters/parameter-edit.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
    ],
  },
  // /////////////////////////////
  // GENERAL OPEN PROCESSES ROUTES
  // ////////////////////////////
  {
    path: '/general/openProcesses',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'common-contract-processes',
        component: () =>
          import('@views/common/open-processes/open-processes.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
      },
      {
        path: ':processUuid',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.lawyer],
        },
        children: [
          {
            path: '',
            name: 'open-process-details',
            component: () =>
              import('@views/common/open-processes/open-process-details.vue'),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin, ...siteClearance.lawyer],
            },
          },
          {
            path: 'rectify',
            name: 'edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/edit-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'edit',
            name: 'full-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/full-edit-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'assignHrCode',
            name: 'assign-hr-code',
            component: () => import('@views/hiring/hr-code/assign-hr-code.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },
  // //////////////////////////////////
  // SITE GENERAL OPEN PROCESSES ROUTES
  // //////////////////////////////////
  {
    path: '/co/:personnelDivision/general/openProcesses',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'open-processes-site',
        component: () =>
          import('@views/common/open-processes/open-processes-site.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
      },
      {
        path: 'extensions/:processUuid/edit',
        name: 'edit-extension-process-site',
        component: () =>
          import('@views/extension/edit-extension/edit-extension-site.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'conversions/:processUuid/edit',
        name: 'edit-conversion-process-site',
        component: () => import('@views/conversion/edit-conversion-site.vue'),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':processUuid',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.lawyer,
          ],
        },
        children: [
          {
            path: '',
            name: 'site-open-process-details',
            component: () =>
              import(
                '@views/common/open-processes/open-process-details-site.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: 'assignHrCode',
            name: 'site-assign-hr-code',
            component: () => import('@views/hiring/hr-code/assign-hr-code.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: 'rectify',
            name: 'site-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/edit-hiring-site.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
          {
            path: 'edit',
            name: 'site-full-edit-hiring',
            component: () =>
              import('@views/hiring/edit-hiring/full-edit-hiring-site.vue'),
            meta: {
              authRequired: true,
              authorize: [],
            },
          },
        ],
      },
    ],
  },
  // /////////////
  // HIRING ADMIN ROUTES
  // /////////////
  {
    path: '/hiring/pendingHrCodeHiringProcesses',
    name: 'pending-hr-code-hiring-processes',
    component: () =>
      import('@views/hiring/hr-code/pending-hr-code-hiring-processes.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  {
    path: '/hiring/multipleHrCodeAssignation',
    name: 'multiple-hr-code-assignation',
    component: () =>
      import('@views/hiring/hr-code/multiple-hr-code-assignation.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ///////////////////
  // SITE HIRING ROUTES
  // //////////////////
  {
    path: '/co/:personnelDivision/hiring',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'site-id-check',
        component: () => import('@views/hiring/id-check/id-check.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'newHiring',
        name: 'site-new-hiring',
        component: () => import('@views/hiring/new-hiring/new-hiring.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'rehiring',
        component: () => import('@views/empty-router-view.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
        children: [
          {
            path: 'rehireInfo/:collaboratorUuid',
            name: 'rehire-info-site',
            component: () =>
              import('@views/hiring/re-hiring/rehire-info/rehire-info.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
          {
            path: 'rehire/:collaboratorUuid',
            name: 'rehire-site',
            component: () => import('@views/hiring/re-hiring/re-hiring.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
              ],
            },
          },
        ],
      },
    ],
  },
  // ////////////////////////////
  // FICTITIOUS ROUTES
  // ////////////////////////////
  // ////////////////////////////
  // HIRE FICTITIOUS
  // ////////////////////////////
  {
    path: '/co/:personnelDivision/hireFictitious',
    name: 'hire-fictitious',
    component: () =>
      import('@router/views/hiring/hire-fictitious/hire-fictitious.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////////
  // FICTITIOUS LIST
  // ////////////////////////////
  {
    path: '/co/:personnelDivision/fictitious',
    name: 'fictitious-list',
    component: () =>
      import('@router/views/collaborator/fictitious/fictitious-list.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////////
  // UPDATE FICTITIOUS
  // ////////////////////////////
  {
    path: '/co/:personnelDivision/fictitious/:fictitiousUuid',
    name: 'fictitious-update',
    component: () =>
      import('@router/views/collaborator/fictitious/fictitious-update.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ////////////////////////////
  // HIRING COLLABORATOR'S ROUTES
  // ////////////////////////////
  {
    path: '/idTokenCheck/:token',
    name: 'id-token-check',
    component: () => import('@views/hiring/id-token-check/id-token-check.vue'),
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  {
    path: '/insertCollaboratorData',
    name: 'insert-collaborator-data',
    component: () =>
      import(
        '@views/hiring/insert-collaborator-data/insert-collaborator-data.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.state.contractManager.verificationDocumentTokenData.token === ''
      ) {
        next({ path: '/' }) // decide view to redirect when user access directly without verification
      } else {
        next()
      }
    },
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  // /////////////
  // LEAVE ROUTES
  // /////////////
  {
    path: '/leave',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
    children: [
      {
        path: 'openLeaveProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'open-leave-processes',
            component: () =>
              import('@views/leave/open-process/open-leave-processes.vue'),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin],
            },
          },
          {
            path: ':processUuid',
            name: 'open-leave-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [...siteClearance.admin],
            },
          },
        ],
      },
      {
        path: 'pendingHrCodeLeaveProcesses',
        name: 'pending-hr-code-leave-processes',
        component: () =>
          import('@views/leave/hr-code/pending-hr-code-leave-processes.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
    ],
  },
  // //////////////////
  // SITE LEAVE ROUTES
  // /////////////////
  {
    path: '/co/:personnelDivision/leave',
    component: () => import('@views/empty-router-view.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
    children: [
      {
        path: 'openLeaveProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'site-open-leave-processes',
            component: () =>
              import('@views/leave/open-process/site-open-leave-processes.vue'),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: ':processUuid',
            name: 'site-open-leave-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
        ],
      },
      {
        path: 'openLeaveOfAbsenceProcesses',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'site-open-leave-of-absence-processes',
            component: () =>
              import(
                '@views/leave/open-process/site-open-leave-of-absence-processes.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
          {
            path: ':processUuid',
            name: 'site-open-leave-of-absence-process-details',
            component: () =>
              import(
                '@views/leave/leave-process-details/leave-process-details.vue'
              ),
            meta: {
              authRequired: true,
              authorize: [
                ...siteClearance.admin,
                ...siteClearance.director,
                ...siteClearance.organizer,
                ...siteClearance.responsible,
                ...siteClearance.lawyer,
              ],
            },
          },
        ],
      },
      {
        path: ':collaboratorUuid',
        name: 'site-leave',
        component: () =>
          import('@/src/router/views/leave/leave-form/leave-form.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////////////
  // TEAM CHANGE PROCESSES
  // /////////////////
  {
    path: '/co/:personnelDivision/teamChangeProcesses',
    name: 'team-change-processes',
    component: () =>
      import(
        '@/src/router/views/common/team-change-processes/team-change-processes.vue'
      ),

    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // CONVERSION ROUTES
  // /////////////////
  {
    path: '/co/:personnelDivision/conversion/:collaboratorUuid',
    name: 'collaborator-conversion',
    component: () =>
      import('@/src/router/views/conversion/conversion-form.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/conversionHandlingAndReliability/:collaboratorUuid',
    name: 'collaborator-conversion-handling-and-reliability',
    component: () =>
      import(
        '@/src/router/views/conversion-handling-reliability/conversion-form.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/conversionLectiveToProductive/:collaboratorUuid',
    name: 'collaborator-conversion-lective-to-productive',
    component: () =>
      import(
        '@/src/router/views/conversion-lective-to-productive/conversion-form.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/co/:personnelDivision/conversionPracticeToCdi/:collaboratorUuid',
    name: 'collaborator-conversion-practice-to-cdi',
    component: () =>
      import(
        '@/src/router/views/conversion-practice-to-cdi/conversion-form.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/pendingHrCodeConversionProcesses',
    name: 'pending-hr-code-conversion-processes',
    component: () =>
      import(
        '@/src/router/views/conversion/pending-hr-code-conversion-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // //////////////////
  // EXTENSION ROUTES
  // /////////////////
  {
    path: '/co/:personnelDivision/extension/:collaboratorUuid',
    name: 'contract-extension',
    component: () => import('@views/contract/contract-extension.vue'),
    meta: {
      authRequired: true,
      authorize: [],
    },
  },
  {
    path: '/pendingHrCodeContractExtensionProcesses',
    name: 'pending-hr-code-contract-extension-processes',
    component: () =>
      import(
        '@views/contract/pending-hr-code-contract-extension-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  // //////////////////
  // TEAM CHANGE
  // /////////////////
  {
    path: '/co/:personnelDivision/teamChange/:collaboratorUuid',
    name: 'collaborator-team-change',
    component: () => import('@views/collaborator/team-change/team-change.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // MUTATION ROUTES
  // /////////////////
  {
    path: '/co/:personnelDivision/mutation/:collaboratorUuid',
    name: 'collaborator-mutation',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin, ...siteClearance.director],
    },
  },
  {
    path: '/co/mutations',
    name: 'mutations-list',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/co/:personnelDivision/mutations',
    name: 'site-mutations-list',
    component: () =>
      import(
        '@views/collaborator/collaborator-mutation/collaborator-mutation-site-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // TIMEBASE REPORT SITE
  // /////////////////
  {
    path: '/co/:personnelDivision/timebaseReport',
    name: 'timebase-report-site',
    component: () =>
      import('@views/common/timebase-report-site/timebase-report-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // //////////////////
  // LABOR CONDITION ROUTES
  // /////////////////

  // //////////////////
  // HOUR CHANGES ROUTES
  // /////////////////
  {
    path: '/co/:personnelDivision/timebaseChange/:collaboratorUuid',
    name: 'collaborator-hour-change',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/hour-change-process.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  {
    path: '/timebaseChangeProcesses',
    name: 'timebase-change-processes-list',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/timebase-change-processes-list.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },
  {
    path: '/co/:personnelDivision/timebaseChangeProcesses',
    name: 'timebase-change-processes-list-site',
    component: () =>
      import(
        '@router/views/laborCondition/hour-change/timebase-change-processes-list-site.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/timebaseChangeProcesses/:processUuid/document',
    name: 'timebase-change-process-document',
    component: () =>
      import(
        '@router/views/laborCondition/document/timebase-change-document-view.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/pendingHrCodeHourChangeProcesses',
    name: 'pending-hr-code-hour-change-processes',
    component: () =>
      import(
        '@views/laborCondition/hour-change/pending-hr-code-hour-change-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },

  // ////////////////////////
  // TEAM OR POSITION CHANGES ROUTES
  // ////////////////////////
  {
    path: '/teamOrPositionChanges',
    name: 'team-or-position-changes',
    component: () =>
      import('@views/laborCondition/team-or-position-changes.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },

  // ////////////////////////
  // LEAVE OF ABSENCE ROUTES
  // ////////////////////////
  {
    path: '/co/leaveOfAbsenceExtension/:processUuid',
    name: 'leave-of-absence-extension',
    component: () =>
      import(
        '@views/leave/leave-of-absence-extension/leave-of-absence-extension.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/co/leaveOfAbsenceReEntry/:processUuid',
    name: 'leave-of-absence-re-entry',
    component: () =>
      import(
        '@views/hiring/leave-of-absence-re-entry/leave-of-absence-re-entry.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/leaveOfAbsenceExtension/:processUuid',
    name: 'leave-of-absence-extension-site',
    component: () =>
      import(
        '@views/leave/leave-of-absence-extension/leave-of-absence-extension.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  {
    path: '/co/:personnelDivision/leaveOfAbsenceReEntry/:processUuid',
    name: 'leave-of-absence-re-entry-site',
    component: () =>
      import(
        '@views/hiring/leave-of-absence-re-entry/leave-of-absence-re-entry.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.lawyer,
      ],
    },
  },
  /// ///////////////////////
  // SALARY ADVANCE ROUTES
  /// //////////////////////
  {
    path: '/co/:personnelDivision/salaryAdvance',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-salary-advance-list',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-list/salary-advance-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: 'create',
        name: 'salary-advance-create',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-create/salary-advance-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: ':salaryAdvanceUuid',
        name: 'salary-advance-update',
        component: () =>
          import(
            '@views/salary-advance/salary-advance-update/salary-advance-update.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.responsible,
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.lawyer,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
    ],
  },

  /// /////////////////////////////////
  // SALARY ADVANCE MANAGEMENT ROUTES
  /// ////////////////////////////////
  {
    path: '/salaryAdvanceManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'salary-advance-management',
        component: () =>
          import(
            '@/src/router/views/salary-advance/salary-advance-management/salary-advance-management.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  /// /////////////////////////
  // TEAMS MANAGEMENT
  /// /////////////////////////
  {
    path: '/teamsManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'teams-management-list',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-list/teams-management-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'create',
        name: 'teams-management-create-team',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-create-team/teams-management-create-team.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':teamUuid',
        name: 'teams-management-update-team',
        component: () =>
          import(
            '@/src/router/views/admin/teams-management/teams-management-update-team/teams-management-update-team.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  /// /////////////////////////
  // SITE MANAGEMENT
  /// /////////////////////////
  {
    path: '/sitesManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'sites-management-site-list',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-site-list/sites-management-site-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'create',
        name: 'sites-management-create-site',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-create-site/sites-management-create-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'update/:personnelDivision',
        name: 'sites-management-update-site',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-update-site/sites-management-update-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'siteManagers/:personnelDivision',
        name: 'sites-management-site-managers',
        component: () =>
          import(
            '@/src/router/views/admin/sites-management/sites-management-site-managers/sites-management-site-managers.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },

  // ////////////////////////
  // HIREABLE WINDOWS
  // ////////////////////////
  {
    path: '/hireableWindows',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'hireable-windows-list',
        component: () =>
          import(
            '@/src/router/views/hiring/hireable-windows/hireable-windows.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: 'create',
        name: 'hireable-windows-create',
        component: () =>
          import('@views/hiring/hireable-windows/hireable-windows-create.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),

        children: [
          {
            path: '',
            name: 'hireable-windows-update',
            component: () =>
              import(
                '@views/hiring/hireable-windows/hireable-windows-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },

  // ////////////////////////
  // OTHER ROUTES
  // ////////////////////////

  // ////////////////////////
  // PENDING HR CODE IBAN CHANGE PROCESSES
  // ////////////////////////
  {
    path: '/pendingHrCodeIbanChangeProcesses',
    name: 'pending-hr-code-iban-change-processes',
    component: () =>
      import(
        '@views/collaborator/collaborator-iban-change/pending-hr-code-iban-change-processes.vue'
      ),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ////////////////////////
  // FEATURE TOGGLE
  // ////////////////////////
  {
    path: '/featureToggle',
    name: 'feature-toggle',
    component: () => import('@views/admin/feature-toggle/feature-toggle.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },

  // //////////////////
  // TIMEBASE REPORT SITE
  // /////////////////
  {
    path: '/co/:personnelDivision/nodebt-report',
    name: 'timebase-report-site',
    component: () => import('@views/leave/nodebt-report/nodebt-report.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },

  // ///////////
  // AUTH ROUTES
  // ///////////
  {
    path: '/login',
    name: 'login',
    component: () => import('@/src/router/views/auth/login-view.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          store
            .dispatch('auth/sendToken', { code: routeTo.query.code })
            .then(() => {
              next()
            })
            .catch((error) => console.warn(error))
        }
      },
    },
  },
  {
    path: '/loginFedId',
    name: 'loginFedId',
    component: () => import('@/src/router/views/auth/login-fed-id.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          store
            .dispatch('auth/getFedIdUrl')
            .then((response) => {
              window.location = response.data.url
            })
            .catch((error) => console.warn(error))
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/src/router/views/auth/logout-view.vue'),
    meta: {
      authRequired: true,
      authorize: [],
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('auth/logOut')
          .then(() => {
            next()
          })
          .catch((error) => console.warn(error))
      },
    },
  },
  {
    path: '/welcomeNewCollaborator',
    name: 'welcomeNewCollaborator',
    component: () => import('@views/hiring/welcome-new-collaborator.vue'),
    meta: {
      authRequired: false,
      authorize: [],
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/:catchAll(.*)*',
    redirect: '404',
  },
]
