<script>
import LocaleNavDropDown from '@components/nav-bars/commonComponents/LocaleNavDropDown.vue'
import LocaleLogoSvg from '@/src/assets/images/svg/LocaleLogoSvg.vue'
import EplanningNavbarLogo from '@/src/assets/images/svg/EplanningNavbarLogo.vue'
import EplanningSupportIcon from '@/src/assets/images/svg/EplanningSupportIcon.vue'
export default {
  components: {
    LocaleNavDropDown,
    LocaleLogoSvg,
    EplanningNavbarLogo,
    EplanningSupportIcon,
  },
  methods: {
    switchLocale(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
  },
}
</script>

<template>
  <nav class="eplanning-navbar rank role">
    <!-- Container -->
    <div
      class="vtmn-mx-auto vtmn-px-4 vtmn-flex vtmn-flex-wrap gt-medium-desktop:vtmn-items-center"
    >
      <!-- Brand logo -->
      <router-link
        class="vtmn-cursor-pointer vtmn-inline-flex vtmn-p-2 gt-medium-desktop:vtmn-mr-4"
        to="/"
      >
        <EplanningNavbarLogo />
      </router-link>

      <!-- Moble btn -->
      <button
        class="gt-medium-desktop:vtmn-hidden vtmn-mt-2 vtmn-ml-auto vtmn-inline-flex vtmn-items-center vtmn-justify-center vtmn-rounded-100 vtmn-h-10 vtmn-w-10 vtmn-border-2 vtmn-btn--icon-alone vtmn-btn_variant--ghost"
        aria-expanded="false"
        @click="toggleNav"
      >
        <span
          class="vtmx-menu-line vtmn-text-active-tertiary"
          aria-hidden="true"
        ></span>
      </button>

      <!-- Right Menu -->
      <div
        class="vtmn-w-full gt-medium-desktop:vtmn-inline-flex gt-medium-desktop:vtmn-w-auto vtmn-mt-2 tablet:vtmn-mb-2 mobile:vtmn-mb-2 gt-medium-desktop:vtmn-mt-0 vtmn-ml-auto"
        :class="{ 'vtmn-hidden': !showMenu }"
      >
        <!-- SUPPORT logo -->
        <a
          class="vtmn-cursor-pointer vtmn-inline-flex vtmn-p-2 gt-medium-desktop:vtmn-mr-2"
          href="https://support.decathlon.net/saw/ess/offeringPage/62759251"
          rel="noopener"
          target="_blank"
        >
          <EplanningSupportIcon />
        </a>

        <!-- Locale Menu -->
        <LocaleNavDropDown
          :dropdown-label="$filters.capitalize($i18n.locale)"
          :drop-down-options="localeNavDropDownOptions"
          @switch-locale="switchLocale"
        >
          <LocaleLogoSvg />
        </LocaleNavDropDown>

        <!-- Account menu -->
        <UsernameAvatarNavDropDown
          :dropdown-label="currentUser?.uid"
          :drop-down-options="usernameDropDownOptions"
          @on:close="closeNav"
        >
        </UsernameAvatarNavDropDown>
      </div>
    </div>
  </nav>
</template>
