import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    pendingHrCodeConversionProcesses: [],
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_PENDING_HR_CODE_CONVERSION_PROCESSES(
    state,
    pendingHrCodeConversionProcesses,
  ) {
    state.pendingHrCodeConversionProcesses = pendingHrCodeConversionProcesses
  },
  RESET_CONVERSION_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  // HR CODE PENDING CONVERSION PROCESSES
  // GET
  async fetchPendingHrCodeConversionProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_HR_CODE_CONVERSION_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/contractManager/conversion.updateHrCodeProcess${queryString}`,
      )
      store.commit('UPDATE_PENDING_HR_CODE_CONVERSION_PROCESSES', res.data.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /co/api/v2/contractManager/conversion.updateHrCodeProcess' +
          queryString,
      )
    }
  },

  // HR CODE CONVERSION RECORDS
  // GET
  async fetchHrCodeConversionRecords(store, processUuid) {
    const queryString =
      processUuid.length > 0
        ? `?filter[conversionProcessesUuid]=${processUuid}`
        : ''
    try {
      return await axios.get(
        `${BASE}/co/api/v2/contractManager/conversion.updateHrCodeProcess/downloadList${queryString}`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/contractManager/conversion.updateHrCodeProcess/downloadList${queryString}`,
      )
    }
  },
  // NEW CONVERSION
  // POST
  async startConversionProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.startProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.startProcess.`,
      )
    }
  },

  // EDIT CONVERSION
  // POST
  async editConversionProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.edit`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.edit.`,
      )
    }
  },

  async startConversionCDIHandlingAndReliabilityProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.startHandlingAndReliabilityProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.startHandlingAndReliabilityProcess.`,
      )
    }
  },

  async startConversionLectiveToProductiveProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.startLectiveToProductive`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.startLectiveToProductive.`,
      )
    }
  },

  async startConversionPracticeToCDIProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.startPracticeToCDI`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.startPracticeToCDI.`,
      )
    }
  },

  // UPDATE MANAGED CONVERSION PROCESSES
  // POST
  async updateManagedConversions(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/contractManager/conversion.updateHrCodeProcess.management`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/contractManager/conversion.updateHrCodeProcess.management.`,
      )
    }
  },
}
