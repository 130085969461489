import axios from '@utils/axiosInstance'
import sortOptionsByKey from '@utils/sortOptionsByKey'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    laborCondition: null,
    positions: [],
    timebaseChangeProcesses: [],
    timebaseChangeDocument: null,
    pendingHrCodeHourChangeProcesses: [],
    teamOrPositionChangeProcesses: [],
    signaturePendingContractLaborCondition: null,
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_LABOR_CONDITION(state, laborCondition) {
    state.laborCondition = laborCondition
  },
  UPDATE_POSITIONS(state, positions) {
    sortOptionsByKey(positions, 'name')
    state.positions = sortPositions(positions)
  },
  UPDATE_TIMEBASE_CHANGE_PROCESSES(state, timebaseChangeProcesses) {
    state.timebaseChangeProcesses = timebaseChangeProcesses.data
  },
  UPDATE_TIMEBASE_CHANGE_DOCUMENT(state, timebaseChangeDocument) {
    state.timebaseChangeDocument = timebaseChangeDocument
  },
  UPDATE_PENDING_HR_CODE_HOUR_CHANGE_PROCESSES(
    state,
    pendingHrCodeHourChangeProcesses,
  ) {
    state.pendingHrCodeHourChangeProcesses = pendingHrCodeHourChangeProcesses
  },
  UPDATE_TEAM_OR_POSITION_CHANGES(state, teamOrPositionChangeProcesses) {
    state.teamOrPositionChangeProcesses = teamOrPositionChangeProcesses
  },
  RESET_LABOR_CONDITION_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION(
    state,
    signaturePendingContractLaborCondition,
  ) {
    state.signaturePendingContractLaborCondition =
      signaturePendingContractLaborCondition
  },
}

export const actions = {
  // POSITIONS
  // GET
  async fetchPositions(store, filters = {}) {
    store.commit('UPDATE_POSITIONS', [])
    try {
      const filtersArr = []
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null || filters[key] !== undefined) {
          filtersArr.push(key + '=' + filters[key])
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(`${BASE}/co/api/v2/positions${queryParams}`)
      store.commit('UPDATE_POSITIONS', res.data)
    } catch (e) {
      throw Error('API Error occurred.')
    }
  },

  // TIMEBASE CHANGE PROCESSES
  // GET
  async fetchTimebaseChangeProcessesAction(store, filters = {}) {
    store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', [])
    const filtersArr = joinFilters(filters)
    const queryParams = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/timebaseChangeProcess${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // TIMEBASE CHANGE PROCESSES SITE
  // GET
  async fetchSiteTimebaseChangeProcessesAction(store, personnelDivision) {
    store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', [])
    const urlTimebase = `${BASE}/co/api/v2/sites/${personnelDivision}/timebaseChangeProcess`
    try {
      const resTimebase = await axios.get(urlTimebase)
      store.commit('UPDATE_TIMEBASE_CHANGE_PROCESSES', resTimebase.data)
    } catch (e) {
      throw Error(`API Error occurred in ${urlTimebase}`)
    }
  },
  // TIMEBASE CHANGE DOCUMENT
  // GET
  async fetchTimebaseChangeDocument(store, processUuid) {
    store.commit('UPDATE_TIMEBASE_CHANGE_DOCUMENT', null)
    try {
      const response = await axios.get(
        `${BASE}/co/api/v2/timebase.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
      store.commit('UPDATE_TIMEBASE_CHANGE_DOCUMENT', response.data)
    } catch (e) {
      throw Error(
        `API Error occurred in  ${BASE}/co/api/v2/timebase.getDocumentPreviewInBase64?uuid=${processUuid}`,
      )
    }
  },

  async fetchPendingHrCodeHourChangeProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_HR_CODE_HOUR_CHANGE_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const resTimebase = await axios.get(
        `${BASE}/co/api/v2/timebase.manageInHrCode${queryString}`,
      )
      store.commit('UPDATE_PENDING_HR_CODE_HOUR_CHANGE_PROCESSES', [
        ...resTimebase.data.data,
      ])
    } catch (error) {
      throw new Error(
        'API Error occurred in fetchPendingHrCodeHourChangeProcesses' + error,
      )
    }
  },

  // TEAM OR POSITION CHANGE PROCESSES
  // GET
  async fetchTeamOrPositionChangeProcessesAction(store, filters) {
    store.commit('UPDATE_TEAM_OR_POSITION_CHANGES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      let resPosition = null
      let resTeam = null

      const changeTypes = ['TEAM_CHANGES', 'POSITION_CHANGES']

      const checker = (arr, target) =>
        target.every((changeType) => arr.includes(changeType))

      if (
        filters.processType === null ||
        checker(filters.processType, changeTypes)
      ) {
        resTeam = await axios.get(
          `${BASE}/co/api/v2/team.changes${queryString}`,
        )
        resPosition = await axios.get(
          `${BASE}/co/api/v2/position.changes${queryString}`,
        )
      } else if (filters.processType.includes('TEAM_CHANGES')) {
        resTeam = await axios.get(
          `${BASE}/co/api/v2/team.changes${queryString}`,
        )
      } else {
        resPosition = await axios.get(
          `${BASE}/co/api/v2/position.changes${queryString}`,
        )
      }

      resTeam = resTeam ? resTeam.data : []
      resPosition = resPosition ? resPosition.data : []
      store.commit('UPDATE_TEAM_OR_POSITION_CHANGES', [
        ...resTeam,
        ...resPosition,
      ])
    } catch (error) {
      throw Error(
        `API Error occurred in fetchTeamOrPositionChangeProcessesAction: ${error}`,
      )
    }
  },

  // /////
  // POST
  // /////

  // UPDATE LABOR CONDITION
  // POST
  async updateLaborConditionDataAction({ store }, payload) {
    let path
    switch (payload.role) {
      case 'hr':
        path = 'laborDataByHr'
        break
      case 'director':
      case 'organizer':
        path = 'laborDataByDirector'
        break
      case 'responsible':
        path = 'laborDataByResponsible'
        break
      case 'collaborator':
        path = 'laborData'
        break
    }
    try {
      return await axios.post(
        `${BASE}/co/api/v2/collaborators/edit.${path}`,
        payload.command,
      )
    } catch (e) {
      throw Error(`API Error occurred at /co/api/v2/collaborators/edit.${path}`)
    }
  },
  // TIMEBASE CHANGE
  // POST
  async startTimebaseChangeAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/laborConditionManager/timebase.startTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/laborConditionManager/timebase.startTimebaseChangeProcess`,
      )
    }
  },
  // SIGN ON PAPER TIMEBASE CHANGE
  // POST
  async signOnPaperTimebaseChangeProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/laborConditionManager/timebase.signOnPaperTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/laborConditionManager/timebase.signOnPaperTimebaseChangeProcess`,
      )
    }
  },
  // CANCELL TIMEBASE CHANGE PROCESS
  // POST
  async cancelTimebaseChangeProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/laborConditionManager/timebase.cancelTimebaseChangeProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/laborConditionManager/timebase.cancelTimebaseChangeProcess`,
      )
    }
  },

  // UPDATE MANAGED HOUR CHANGE PROCESSES
  // POST
  async updateManagedHourChangeProcessesAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/laborConditionManager/process.updateHrCodeTimebaseChangeProcessManagement`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/laborConditionManager/process.updateHrCodeTimebaseChangeProcessManagement.`,
      )
    }
  },

  // HR CODE HOUR CHANGE RECORDS
  // POST
  async handleHrCodeHourChangeRecords(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/process.exportHrCodeData`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /co/api/v2/process.exportHrCodeData`,
      )
    }
  },

  // SEND DOCUMENT TO SIGNER PROVIDER
  // POST
  async sendTimebaseChangeDocumentToSignerProviderAction(store, payload) {
    const url = `${BASE}/co/api/v2/laborConditionManager/timebase.sendTimebaseChangeProcessDocument`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },

  // SIGNATURE PENDING CONTRACT PROCESSES LABOR CONDITION
  // GET
  async fetchSignaturePendingContractLaborCondition(store) {
    try {
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/laborConditionManager/collaborator.signaturePendingLaborConditionProcesses`,
      )
      store.commit(
        'UPDATE_PENDING_CONTRACTS_PROCESSES_LABOR_CONDITION',
        res.data,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/laborConditionManager/collaborator.signaturePendingLaborConditionProcesses`,
      )
    }
  },

  // VALIDATE HR FOR TIMEBASE CHANGE PROCESS
  // POST
  async validateHrTimebaseChangeProcessAction(store, payload) {
    const url = `${BASE}/co/api/v2/laborConditionManager/timebase.validateHrTimebaseChangeProcess`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
}

function joinFilters(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null || filters[key] !== undefined) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  return filtersArr
}

function sortPositions(positions) {
  return positions.sort((x, y) => {
    return +x.id === 2 || +x.id === 10
      ? -1
      : +y.id === 2 || +y.id === 10
      ? 1
      : 0
  })
}
