import {
  contractProcessTypes,
  processStatusesObj,
} from '@utils/constantHelpers'
import { availableTransitions } from './constantHelpers'

export const siteClearance = {
  admin: ['ADMINISTRATOR', 'HR', 'HR_LEADER'],
  directorCoach: ['DIRECTOR_COACH'],
  director: ['DIRECTOR', 'DIRECTOR_COACH', 'DEPARTMENT_LEADER'],
  responsible: ['RESPONSIBLE', 'TEAM_LEADER'],
  collaborator: ['COLLABORATOR', 'RESPONSIBLE_COLLABORATOR'],
  lawyer: ['LAWYER'],
  organizer: ['ORGANIZER'],
  patron: ['PATRON'],
  responsibleCollaborator: ['RESPONSIBLE_COLLABORATOR'],
  multicenterLeader: ['MULTI_CENTER_LEADER'],
}

export function showSignOnPaperButton(actorRoles, processStatus) {
  const signableByDrAndRs = [
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
  ]
  if (
    availableTransitions.SIGN_ON_PAPER.includes(processStatus) &&
    isAuthorized(actorRoles, siteClearance.admin)
  ) {
    return true
  } else if (
    availableTransitions.SIGN_ON_PAPER.includes(processStatus) &&
    signableByDrAndRs.includes(processStatus) &&
    (isAuthorized(actorRoles, siteClearance.organizer) ||
      isAuthorized(actorRoles, siteClearance.director) ||
      isAuthorized(actorRoles, siteClearance.lawyer))
  ) {
    return true
  } else return false
}

export function showCancelButton(processType, processStatus) {
  const cancelableStatusForConversion = [
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
  ]

  if (processType === 'CONVERSION_CONTRACT_PROCESS_COL') {
    return (
      availableTransitions.CANCELLABLE_STATUSES.includes(processStatus) ||
      cancelableStatusForConversion.includes(processStatus)
    )
  } else
    return availableTransitions.CANCELLABLE_STATUSES.includes(processStatus)
}

export function showAbortButton(actorRoles, processType, processStatus) {
  return (
    availableTransitions.ABORTABLE_STATUSES.includes(processStatus) &&
    processType === 'HIRING_CONTRACT_PROCESS_COL' &&
    isAuthorized(actorRoles, siteClearance.admin)
  )
}

export function showSendButton(processType, processStatus) {
  if (
    processType === 'EXTENSION_CONTRACT_PROCESS_COL' ||
    processType === 'CONVERSION_CONTRACT_PROCESS_COL'
  ) {
    return availableTransitions.SEND_DOCUMENT_TRANSITION.includes(processStatus)
  }
  return false
}

export function showCancelLeaveProcessButton(actorRoles, processStatus) {
  return (
    availableTransitions.LEAVE_TRANSITIONS.CANCELLABLE_STATUSES.includes(
      processStatus,
    ) && isAuthorized(actorRoles, siteClearance.admin)
  )
}

export function showViewContractButton(actorRoles, processStatus) {
  return (
    availableTransitions.VIEWABLE_CONTRACT_STATUSES.includes(processStatus) &&
    isAuthorized(actorRoles, siteClearance.admin)
  )
}

export function isAuthorized(rolesArr, clearanceArr) {
  return rolesArr.some((element) => {
    return clearanceArr.some((e) => e === element.role.code)
  })
}

/** ******* Contract process actions permissions ******/

export function isAuthorizedToSignOnPaper(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToSignOnPaper = false

  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COL:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COL:
      isAuthorizedToSignOnPaper =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToSignOnPaper
}

export function isAuthorizedToSendEmail(processType, currentStatus) {
  let isAuthorizedToSendEmail = false

  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COL:
      isAuthorizedToSendEmail = [
        processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
      ].includes(currentStatus)
      break
  }
  return isAuthorizedToSendEmail
}

export function isAuthorizedToCancelProcess(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToCancelProcess = false
  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COL:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COL:
      isAuthorizedToCancelProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
      isAuthorizedToCancelProcess =
        (isAuthorized(actorRoles, siteClearance.admin) &&
          [
            processStatusesObj.WAITING_FOR_HR_DATA,
            processStatusesObj.WAITING_TO_SEND_CONTRACT,
            processStatusesObj.WAITING_TO_SIGN_CONTRACT,
          ].includes(currentStatus)) ||
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
          processStatusesObj.WAITING_FOR_COLLABORATOR_DATA,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToCancelProcess
}

export function isAuthorizedToEditProcess(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToEditProcess = false
  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COL:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COL:
      isAuthorizedToEditProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COL:
      isAuthorizedToEditProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_HR_DATA,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
  }

  return isAuthorizedToEditProcess
}

export function isAuthorizedToViewPdfContract(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToViewPdfContract = false

  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COL:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COL:
      isAuthorizedToViewPdfContract = isAuthorized(
        actorRoles,
        siteClearance.admin,
      )
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
      isAuthorizedToViewPdfContract =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_HR_DATA,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
          processStatusesObj.SIGNED,
          processStatusesObj.SIGNED_ON_PAPER,
          processStatusesObj.COMPLETED_SUCCESS,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToViewPdfContract
}

export function isAuthorizedToRectifyProcess(processType, currentStatus) {
  let isAuthorizedToRectifyProcess = false
  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COL:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COL:
      isAuthorizedToRectifyProcess = [
        processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
        processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
        processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
        processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
        processStatusesObj.WAITING_FOR_COLLABORATOR_DATA,
      ].includes(currentStatus)
      break
  }
  return isAuthorizedToRectifyProcess
}

export function isAuthorizedToSendContract(processType, processStatus) {
  if (
    processType === contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COL ||
    processType === contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COL
  ) {
    return processStatusesObj.WAITING_TO_SEND_CONTRACT.includes(processStatus)
  }
  return false
}

/** ********************************************* */

export const centralServicesPersonnelDivision = 'co0002'

//
// Special roles such as HR are excluded from this list, since they should be assigned additionally to one of next listed
export const rolesByHierarchyLevel = {
  DIRECTOR: [
    'DIRECTOR',
    'DIRECTOR_COACH',
    'DEPARTMENT_LEADER',
    'ORGANIZER',
    'MULTI_CENTER_LEADER',
    'COUNTRY_LEADER',
  ],
  RESPONSIBLE: ['RESPONSIBLE', 'TEAM_LEADER'],
  COLLABORATOR: ['COLLABORATOR', 'RESPONSIBLE_COLLABORATOR'],
}
