import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        housingTypes: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_HOUSING_TYPES(state, housingTypes) {
        state.housingTypes = housingTypes
    }
}

export const actions = {
    async fetchHousingTypes(store) {
        try {
            store.commit('UPDATE_HOUSING_TYPES', [])

            const url = `${BASE}/co/api/v2/housingTypes`
            const res = await axios.get(url)
            store.commit('UPDATE_HOUSING_TYPES', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/housingTypes.')
        }
    },
}