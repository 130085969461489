import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        bloodTypes: [],
        bloodGroups: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_BLOOD_TYPES(state, bloodTypes) {
        state.bloodTypes = bloodTypes
    },
    UPDATE_BLOOD_GROUPS(state, bloodGroups) {
        state.bloodGroups = bloodGroups
    },
}

export const actions = {
    async fetchBloodTypes(store) {
        try {
            store.commit('UPDATE_BLOOD_TYPES', [])

            const url = `${BASE}/co/api/v2/bloodTypes`
            const res = await axios.get(url)
            store.commit('UPDATE_BLOOD_TYPES', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/bloodTypes.')
        }
    },

    async fetchBloodGroups(store) {
        try {
            store.commit('UPDATE_BLOOD_GROUPS', [])

            const url = `${BASE}/co/api/v2/bloodGroups`
            const res = await axios.get(url)
            store.commit('UPDATE_BLOOD_GROUPS', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/bloodGroups.')
        }
    },
}