import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        timebaseWeeklyProjections: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_PROJECTIONS(state, timebaseWeeklyProjections) {
        state.timebaseWeeklyProjections = timebaseWeeklyProjections
    }
}

export const actions = {
    async fetchTimebaseWeeklyProjections(store) {
        try {
            store.commit('UPDATE_PROJECTIONS', [])

            const url = `${BASE}/co/api/v2/timebaseWeeklyProjections`
            const res = await axios.get(url)
            store.commit('UPDATE_PROJECTIONS', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/timebaseWeeklyProjections.')
        }
    },
}