export const availableTransitions = {
  ABORTABLE_STATUSES: ['WAITING_TO_SIGN_CONTRACT'],
  CANCELLABLE_STATUSES: [
    'WAITING_FOR_COLLABORATOR_DATA',
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
    'WAITING_FOR_HR_DATA',
    'WAITING_FOR_LEGAL_VALIDATION',
    'QUEUED_SEND_EMAIL_PENDING',
    'WAITING_TO_SEND_CONTRACT',
  ],
  DIRECTOR_VALIDATION_STATUSES: [
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  ],
  LEGAL_VALIDATION_STATUSES: [
    'WAITING_FOR_LEGAL_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  ],
  RECTIFY_CONTRACT_DATA_STATUSES: [
    'QUEUED_SEND_EMAIL_PENDING',
    'WAITING_FOR_COLLABORATOR_DATA',
  ],
  MODIFY_CONTRACT_DATA_STATUSES: [
    'WAITING_FOR_HR_DATA',
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
  ],
  HR_CODE_ASSIGNABLE_STATUSES: ['WAITING_FOR_HR_DATA'],
  SEND_EMAIL_COLLABORATOR_START_STATUSES: [
    'WAITING_FOR_COLLABORATOR_DATA',
    'QUEUED_SEND_EMAIL_PENDING',
  ],
  LEAVE_TRANSITIONS: {
    CANCELLABLE_STATUSES: ['WAITING_TO_BE_EXECUTED', 'WAITING_TO_START'],
  },
  SIGN_ON_PAPER: [
    'WAITING_FOR_DIRECTOR_VALIDATION',
    'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
    'WAITING_FOR_HR_DATA',
    'WAITING_FOR_LEGAL_VALIDATION',
    'WAITING_TO_SIGN_CONTRACT',
    'WAITING_TO_SEND_CONTRACT',
    'QUEUED_SEND_EMAIL_PENDING',
    'CONTRACT_SENT',
  ],
  SEND_DOCUMENT_TRANSITION: ['WAITING_TO_SEND_CONTRACT', 'CONTRACT_SENT'],
  VIEWABLE_CONTRACT_STATUSES: [
    'WAITING_TO_SEND_CONTRACT',
    'WAITING_TO_SIGN_CONTRACT',
    'WAITING_FOR_HR_DATA',
    'SIGNED',
    'SIGNED_ON_PAPER',
    'COMPLETED_SUCCESS',
  ],
}

export const collaboratorStatuses = [
  'DELETED',
  'EXPATRIATE',
  'HIRED',
  'HIRED_CDI_DF_ACTIVE',
  'HIRED_CDI_DF_INACTIVE',
  'HIRING_PROCESS',
  'LEAVE_OF_ABSENCE',
  'LEAVE_PROCESS',
  'WAITING_FOR_HR_DATA_AND_SIGNATURE',
  'CONTRACT_CHANGE_PROCESS',
  'ONGOING_CDI_DF_CALL_PROCESS',
]

export const processStatuses = [
  'COMPLETED_SUCCESS',
  'WAITING_FOR_HR_DATA',
  'WAITING_FOR_COLLABORATOR_DATA',
  'WAITING_TO_BE_EXECUTED',
  'WAITING_TO_SEND_CONTRACT',
  'QUEUED_SEND_EMAIL_PENDING',
  'WAITING_TO_SEND_CONTRACT',
  'WAITING_TO_SIGN_CONTRACT',
  'WAITING_TO_START',
  'WAITING_FOR_DIRECTOR_VALIDATION',
  'WAITING_FOR_LEGAL_VALIDATION',
  'WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION',
  'SIGNED',
]

export const processStatusesObj = processStatuses.reduce((obj, str) => {
  obj[str] = str
  return obj
}, {})

export const mutationProcessStatuses = [
  'WAITING_FOR_APPROVAL',
  'PENDING_MUTATION_COLLABORATOR_PROCESS',
  'CANCEL_MUTATION_COLLABORATOR_PROCESS',
  'FINISH_MUTATION_COLLABORATOR_PROCESS',
]

export const timebaseChangeProcessStatuses = [
  'CANCELLED',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'NOT_SIGNED',
  'PENDING_SENDING',
  'PENDING_SIGNATURE',
]
export const complementaryPactProcessStatuses = [
  'CANCELLED',
  'SIGNED',
  'SIGNED_ON_PAPER',
  'NOT_SIGNED',
  'PENDING_SENDING',
  'PENDING_SIGNATURE',
]
export const shiftReductionInitialStatus = [
  'SIGNED_ON_PAPER',
  'PENDING_SIGNATURE',
]

export const positionsWithOutTeam = [
  'DIRECTOR DE TIENDA',
  'DIRECTOR TALLER REGIONAL',
  'DIRECTOR ALMACÉN',
  'ORGANIZADOR SEGURIDAD EXP',
]

export const leaveOfAbsenceLeaveReasons = [
  'VOLUNTARY_LEAVE',
  'FAMILY_CARE_LEAVE',
  'MATERNITY_LEAVE',
  5,
  6,
  7,
]

export const leaveReasons = [
  'CONSUMED_TEMPORARY_INCAPACITY',
  'VOLUNTARY_RESIGNATION',
  'DISMISSAL',
  'MATERNITY_LEAVE',
  'FAMILY_CARE_LEAVE',
  'VOLUNTARY_LEAVE',
  'CONTRACT_END',
  'HAS_NOT_STARTED_TO_WORK',
  'NOT_PASSED_TRIAL',
  'PENSIONIST',
  'OWN_AGREEMENT_LEAVE',
  'WORKER_DEATH',
  'DISMISS_WITH_FAIR_CAUSE',
  'DISMISS_WITHOUT_FAIR_CAUSE',
  'JOB_ABANDONMENT',
  'EMPLOYER_REPLACEMENT',
]

export const leaveProcessTypes = {
  LEAVE_OF_ABSENCE_PROCESS_COL: 'LEAVE_OF_ABSENCE_PROCESS_COL',
  LEAVE_PROCESS_COL: 'LEAVE_PROCESS_COL',
}

export const leaveOfAbsenceProcessTypes = ['LEAVE_OF_ABSENCE_PROCESS_COL']

export const contractTypeFullPartial = {
  FULL_TIME: 'TC',
  PARTIAL_TIME: 'TP',
}

export const contractTypeDuration = {
  CDD: 'CDD',
  CDI: 'CDI',
}

export const contractTypes = [
  'CDD',
  'CDI',
  'TYPE_CDD_LECTIVE_APPRENTICE',
  'TYPE_CDD_PRODUCTIVE_APPRENTICE',
]

export const contractWeeklyBasetimes = {
  TC: {
    min: 40,
    max: 40,
  },
  TP: {
    min: 24,
    max: 24,
  },
}

export const cddContractTypeCodes = ['503', '403']

export const cdiContractTypeCodes = ['100', '200']

export const cddApprenticeTypeCodes = ['600', '700']

export const contractProcessTypes = {
  HIRING_CONTRACT_PROCESS_COL: 'HIRING_CONTRACT_PROCESS_COL',
  CONVERSION_CONTRACT_PROCESS_COL: 'CONVERSION_CONTRACT_PROCESS_COL',
  EXTENSION_CONTRACT_PROCESS_COL: 'EXTENSION_CONTRACT_PROCESS_COL',
  REHIRING_CONTRACT_PROCESS_COL: 'REHIRING_CONTRACT_PROCESS_COL',
  REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COL:
    'REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS',
  IMPORT_CONTRACT_PROCESS_COL: 'IMPORT_CONTRACT_PROCESS',
}

export const contractProcessTypesArray = [
  'HIRING_CONTRACT_PROCESS_COL',
  'CONVERSION_CONTRACT_PROCESS_COL',
  'CONVERSION_CONTRACT_CDI_HANDLING_AND_RELIABILITY_PROCESS_COL',
  'EXTENSION_CONTRACT_PROCESS_COL',
  'REHIRING_CONTRACT_PROCESS_COL',
]

export const aggregates = {
  HIRING_CONTRACT_PROCESS_COL: 'hiring',
  CONVERSION_CONTRACT_PROCESS_COL: 'conversion',
  CONVERSION_CONTRACT_CDI_HANDLING_AND_RELIABILITY_PROCESS_COL:
    'conversion-cdi-handling-and-reliability',
  EXTENSION_CONTRACT_PROCESS_COL: 'extension',
  REHIRING_CONTRACT_PROCESS_COL: 'rehiring',
}

export const peopleWithDisabilityContractCodes = ['130', '230', '430', '530']

export function urlQueryStringBuilder(payload = {}) {
  const queryOptions = []
  if ({}.hasOwnProperty.call(payload, 'sort')) {
    queryOptions.push(`sort=${payload.sort}`)
  }

  if ({}.hasOwnProperty.call(payload, 'filters')) {
    Object.keys(payload.filters).forEach((key) => {
      if (payload.filters[key] && payload.filters[key].length) {
        return queryOptions.push(`filter[${key}]=${payload.filters[key]}`)
      }
    })
  }

  let queryString = queryOptions.length > 0 ? '?' + queryOptions.join('&') : ''
  queryString = encodeURI(queryString)

  return queryString
}

export const collaboratorPositionNumberCodes = [
  '293', // ASESOR COMERCIAL DEPORTIVO
]
export const responsiblePositionNumberCodes = [
  '442', // RESPONSABLE DE SECCIÓN
  '390', // RESPONSABLE DE SECCION EN FORMACION
]
export const organizerPositionNumberCodes = [
  '311', // ORGANIZADOR TIENDA
]
export const directorPositionNumberCodes = [
  '301', // DIRECTOR DE TIENDA
  '389', // DIRECTOR TIENDA EN FORMACION
]

export const positionsWithAutomaticSalaryCalculation = [
  ...collaboratorPositionNumberCodes,
  ...responsiblePositionNumberCodes,
]
export const affiliationTypeNames = {
  AFFILIATION_TYPE_SEVERANCE_FUND: 'Fondo de Cesantías',
  AFFILITATION_TYPE_COMPENSATION_FUND: 'Caja de Compensación',
  AFFILITATION_TYPE_HEALTH_FUND: 'Salud',
  AFFILITATION_TYPE_MANDATORY_PENSION: 'Pensión obligatoria',
}
