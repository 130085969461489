import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        affiliationTypes: [],
        affiliationSubtypes: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_AFFILIATION_TYPES(state, affiliationTypes) {
        state.affiliationTypes = affiliationTypes
    },

    UPDATE_AFFILIATION_SUBTYPES(state, affiliationSubtypes) {
        state.affiliationSubtypes = affiliationSubtypes
    }
}

export const actions = {
    async fetchAffiliationTypes(store, contractType) {
        try {
            store.commit('UPDATE_AFFILIATION_TYPES', [])

            const filtersArr = []
            filtersArr.push(`filter[contractTypeCode]=${contractType.code}`)
            const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''

            const url = `${BASE}/co/api/v2/getAffiliationTypes${queryString}`
            const res = await axios.get(url)
            store.commit('UPDATE_AFFILIATION_TYPES', res.data)
            return res.data
        } catch (error) {
            throw new Error('API Error occurred in /api/v2/getAffiliationTypes.')
        }
    },

    async fetchAffiliationSubtypes(store, payload) {
        try {
            store.commit('UPDATE_AFFILIATION_SUBTYPES', [])

            let url = ''
            if (payload.regionId) {
               url = `${BASE}/co/api/v2/getAffiliationSubtypes/${payload.affiliationTypeId}/${payload.regionId}`
            } else {
               url = `${BASE}/co/api/v2/getAffiliationSubtypes/${payload.affiliationTypeId}`
            }

            const res = await axios.get(url)
            store.commit('UPDATE_AFFILIATION_SUBTYPES', res.data)
            return res.data
        } catch (error) {
            throw new Error('API Error occurred in /api/v2/getAffiliationSubtypes.')
        }
    }
}
