import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        laborHazardTaxes: []
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_LABOR_HAZARD_TAXES(state, laborHazardTaxes) {
        state.laborHazardTaxes = laborHazardTaxes
    },
    RESET_LABOR_HAZARD_TAXES_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export const actions = {
    async fetchLaborHazardTaxes(store) {
        try {
            store.commit('RESET_LABOR_HAZARD_TAXES_STATE', [])

            const url = `${BASE}/co/api/v2/laborHazardTaxes`
            const res = await axios.get(url)
            store.commit('UPDATE_LABOR_HAZARD_TAXES', res.data)
        } catch (error) {
            throw new Error('API Error occurred in api/laborHazardTaxes.')
        }
    },
}