import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
    return {
        salaryData: {}
    }
}

export const state = getDefaultState()

export const mutations = {
    UPDATE_SALARY_DATA(state, salaryData) {
        state.salaryData = salaryData
    }
}

export const actions = {
    async fetchSalaryData(store, filters) {
        try {
            store.commit('UPDATE_SALARY_DATA', [])

            const positionUuid = filters.positionUuid
            const timebaseWeeklyProjection = parseInt(filters.timebaseWeeklyProjection)

            const url = `${BASE}/co/api/v2/salaryBase/${positionUuid}/${timebaseWeeklyProjection}`
            const res = await axios.get(url)
            store.commit('UPDATE_SALARY_DATA', res.data.data)
            return res.data.data
        } catch (error) {
            console.log(error)
            throw new Error('API Error occurred in /co/api/v2/salaryBase.')
        }
    },
}
