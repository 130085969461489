<script>
import AnonymousNavBar from '@components/nav-bars/anonymous-nav-bar/anonymous-nav-bar.vue'

export default {
  components: { AnonymousNavBar },
}
</script>

<template>
  <div class="vtmn-mx-auto vtmn-mb-0">
    <AnonymousNavBar class="colorNav" />
    <div class="vtmn-min-w-min vtmn-max-w-7xl vtmn-p-5 vtmn-mt-5 vtmn-mb-0 vtmn-mx-auto">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  padding: 20px;
  margin: 20px auto 0 auto;
}
.colorNav {
  @extend %colorNav;
}
</style>
