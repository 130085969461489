import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

export const state = {
  collaboratorSalaryAdvances: [],
  salaryAdvancesHR: [],
}
export const getters = {}
export const mutations = {
  UPDATE_COLLABORATOR_SALARY_ADVANCES(state, collaboratorSalaryAdvances) {
    state.collaboratorSalaryAdvances = collaboratorSalaryAdvances
  },
  UPDATE_SALARY_ADVANCES_HR(state, salaryAdvancesHR) {
    state.salaryAdvancesHR = salaryAdvancesHR
  },
}
export const actions = {
  // FETCH COLLABORATOR SALARY ADVANCE
  async fetchCollaboratorSalaryAdvancesAction({ commit }, filters) {
    commit('UPDATE_COLLABORATOR_SALARY_ADVANCES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/salaryAdvance/list${queryString}`
    try {
      const resp = await axios.get(url)
      commit('UPDATE_COLLABORATOR_SALARY_ADVANCES', resp.data)
      return resp
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // CREATE SALARY ADVANCE
  async createSalaryAdvanceAction({ commit }, payload) {
    const url = `${BASE}/co/api/v2/salaryAdvance/create`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // UPDATE SALARY ADVANCE
  async updateSalaryAdvanceAction({ commit }, payload) {
    const url = `${BASE}/co/api/v2/salaryAdvance/update`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // DELETE SALARY ADVANCE
  async deleteSalaryAdvanceAction({ commit }, payload) {
    const url = `${BASE}/co/api/v2/salaryAdvance/delete`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // FETCH SALARY ADVANCE BY HR
  async fetchSalaryAdvancedByHRAction({ commit }, filters) {
    commit('UPDATE_SALARY_ADVANCES_HR', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/salaryAdvancedByHR${queryString}`
    try {
      const resp = await axios.get(url)
      commit('UPDATE_SALARY_ADVANCES_HR', resp.data)
      return resp
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // MANAGE SALARY ADVANCE BY HR
  async manageSalaryAdvancedHRAction({ commit }, payload) {
    const url = `${BASE}/co/api/v2/manageSalaryAdvancedByHR`
    try {
      const resp = await axios.post(url, payload)
      return resp
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
}
