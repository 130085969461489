import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    featuresAll: null,
    featuresSite: [],
    teamPermissionsSite: [],
    teamPermissionsCollaborator: null,
    directorCoaches: [],
    directorCoachSites: [],
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_FEATURES_ALL(state, featuresAll) {
    state.featuresAll = featuresAll
  },
  UPDATE_FEATURES_SITE(state, featuresSite) {
    state.featuresSite = featuresSite
  },
  UPDATE_TEAM_PERMISSIONS_SITE(state, teamPermissionsSite) {
    state.teamPermissionsSite = teamPermissionsSite
  },
  UPDATE_COLLABORATOR_TEAM_PERMISSIONS(state, teamPermissionsCollaborator) {
    state.teamPermissionsCollaborator = teamPermissionsCollaborator
  },
  RESET_ADMIN_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_DIRECTOR_COACHES(state, collaborators) {
    state.directorCoaches = collaborators
  },
  UPDATE_DIRECTOR_COACH_SITES(state, sites) {
    state.directorCoachSites = sites
  },
}

export const actions = {
  // FEATURES
  // GET
  async fetchFeaturesAll(store) {
    store.commit('UPDATE_FEATURES_ALL', null)
    try {
      const response = await axios.get(
        `${BASE}/co/api/v2/featuresAvailability/features`,
      )
      store.commit('UPDATE_FEATURES_ALL', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at /co/api/v2/featuresAvailability/features.`,
      )
    }
  },
  // FEATURES SITE
  // GET
  async fetchFeaturesSite(store, personnelDivision) {
    store.commit('UPDATE_FEATURES_SITE', [])
    try {
      const response = await axios.get(
        `${BASE}/co/api/v2/featuresAvailability/features/personnelDivision/${personnelDivision}`,
      )
      store.commit('UPDATE_FEATURES_SITE', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at /co/api/v2/featuresAvailability/features/personnelDivision/${personnelDivision}.`,
      )
    }
  },
  // TEAM PERMISSIONS SITE
  // GET
  async fetchTeamPermissionsSite(store, personnelDivision) {
    store.commit('UPDATE_TEAM_PERMISSIONS_SITE', [])
    try {
      const response = await axios.get(
        `${BASE}/co/api/v2/team.permissions?filter[personnelDivision]=${personnelDivision}`,
      )
      store.commit('UPDATE_TEAM_PERMISSIONS_SITE', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/co/api/v2/team.permissions?filter[personnelDivision]=${personnelDivision}: ${error}`,
      )
    }
  },
  // TEAM PERMISSIONS COLLABORATOR
  // GET
  async fetchTeamPermissionsCollaborator(store, collaboratorUuid) {
    store.commit('UPDATE_COLLABORATOR_TEAM_PERMISSIONS', null)
    try {
      const response = await axios.get(
        `${BASE}/co/api/v2/team.permissions/collaborator/${collaboratorUuid}`,
      )
      store.commit('UPDATE_COLLABORATOR_TEAM_PERMISSIONS', response.data)
      return response
    } catch (error) {
      throw new Error(
        `API Error occurred at ${BASE}/co/api/v2/team.permissions/collaborator/${collaboratorUuid}: ${error}`,
      )
    }
  },
  // ////
  // POST
  // ////

  // FEATURE SITE ADD
  // POST
  async featureAddSiteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/featuresAvailability/features.create`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/featuresAvailability/features.create`,
      )
    }
  },
  // FEATURE SITE TOGGLE
  // POST
  async featureToggleSiteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/featuresAvailability/features.toggle`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/featuresAvailability/features.toggle`,
      )
    }
  },
  // FEATURE SITE TOGGLE
  // POST
  async uploadHolidaysAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/holiday.importFromCSV`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/calendar/holiday.importFromCSV: ${e}`,
      )
    }
  },
  // TEAM PERMISSIONS MANAGE
  // POST
  async manageCollaboratorTeamPermissionsAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/team.managePermissions`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/team.managePermissions: ${e}`,
      )
    }
  },
  async fetchDirectorCoaches(store) {
    store.commit('UPDATE_DIRECTOR_COACHES', [])
    const url = `${BASE}/co/api/v2/directorCoaches`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_DIRECTOR_COACHES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  async fetchDirectorCoachSites(store, collaboratorUuid) {
    store.commit('UPDATE_DIRECTOR_COACH_SITES', [])
    const url = `${BASE}/co/api/v2/directorCoaches/${collaboratorUuid}/sites`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_DIRECTOR_COACH_SITES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
  async updateDirectorCoachSites({ store }, payload) {
    const url = `${BASE}/co/api/v2/directorCoaches/update`
    try {
      return await axios.post(url, payload)
    } catch (e) {
      throw Error(`API Error occurred at ${url}: ${e}`)
    }
  },
}
