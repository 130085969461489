import axios from '@utils/axiosInstance'
import sortOptionsByKey from '@utils/sortOptionsByKey'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaboratorContracts: [],
    contractTypes: [],
    contractType: {},
    contractBasicDocument: null,
    substitutionReasons: [],
  }
}

export const state = getDefaultState()

export const getters = {
  getContractTypeTypes: (state) => () => {
    return [
      ...new Set(state.contractTypes.map((contractType) => contractType.type)),
    ]
  },
}

export const mutations = {
  UPDATE_COLLABORATOR_CONTRACTS(state, collaboratorContracts) {
    state.collaboratorContracts = collaboratorContracts
  },
  UPDATE_CONTRACT_TYPES_COUNTRY(state, contractTypesCountry) {
    state.contractTypes = contractTypesCountry
  },
  UPDATE_CONTRACT_TYPE(state, contractType) {
    state.contractType = contractType
  },
  ADD_CONTRACT_TYPES_COUNTRY(state, contractTypesCountry) {
    state.contractTypes.push(contractTypesCountry)
  },
  UPDATE_CONTRACT_BASIC_DOCUMENT(state, contractBasicDocument) {
    state.contractBasicDocument = contractBasicDocument
  },
  RESET_CONTRACT_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_SUBSTITUTION_REASONS(state, substitutionReasons) {
    sortOptionsByKey(substitutionReasons, 'description')
    state.substitutionReasons = substitutionReasons
  },
}

export const actions = {
  // COLLABORATOR CONTRACTS
  // GET
  async fetchCollaboratorContracts(store) {
    try {
      store.commit('UPDATE_COLLABORATOR_CONTRACTS', [])
      const res = await axios.get('api/contracts')
      store.commit('UPDATE_COLLABORATOR_CONTRACTS', res.data)
    } catch (error) {
      throw new Error('API Error occurred in api/contracts.')
    }
  },

  // CONTRACT TYPES COUNTRY
  // GET
  async fetchContractTypesCountry(store, filters = {}, addFlag = false) {
    try {
      if (!addFlag) {
        store.commit('UPDATE_CONTRACT_TYPES_COUNTRY', [])
      }
      const filtersArr = []
      Object.keys(filters).forEach(function (key) {
        if (filters[key]) {
          filtersArr.push(`${key}=${filters[key]}`)
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(
        `${BASE}/co/api/v2/contractTypes${queryParams}`,
      )
      store.commit('UPDATE_CONTRACT_TYPES_COUNTRY', res.data)
    } catch (error) {
      throw new Error('API Error occurred in /co/api/v2/contractTypes.')
    }
  },

  // CONTRACT TYPES COUNTRY
  // GET
  async fetchContractBasicDocumentAction(store, filters = {}) {
    const summary = filters.summary ? 'summary' : 'full'
    try {
      store.commit('UPDATE_CONTRACT_BASIC_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/contracts/document.${summary}/collaborator/${filters.collaboratorUuid}/startDate/${filters.contractStartDate}`,
      )
      store.commit('UPDATE_CONTRACT_BASIC_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/contracts/document.${summary}/collaborator/${filters.collaboratorUuid}/startDate/${filters.contractStartDate}`,
      )
    }
  },
  async fetchSubstitutionReasonsAction(store) {
    store.commit('UPDATE_SUBSTITUTION_REASONS', [])
    const url = `${BASE}/co/api/v2/substitutionReasons`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SUBSTITUTION_REASONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },
}
